import { Neb, HttpRequest, Account } from 'nebulas'
import NebPay from 'nebpay.js'
// neb chain id
export const neb_chain_id = Number(process.env.REACT_APP_NEB_CHAIN_ID)

// neb network map
export const NEB_NETWORK = {
  1: 'mainnet',
  1001: 'testnet'
}

export const CALL_API_FROM_ADDR = 'n1KSYoLoqen2bYmZvUC3JXT9TeXrAhkzrPy'

// set gas price, limit default
export const GAS = {
  PRICE: '20000000000',
  LIMIT: '2000000',
  LIMIT_HIGH: '8000000'
}

const getContract = () => {
  return {
    host: `https://${NEB_NETWORK[neb_chain_id]}.nebulas.io`,
    chain_id: neb_chain_id,
    call_api_from: CALL_API_FROM_ADDR
  }
}

const { chain_id, host, call_api_from } = getContract()

export const EXPLORER_PREFIXES = {
  1: '#/',
  1001: '#/testnet/'
}

export function getExplorerLink(data, type) {
  const prefix = 'https://explorer.nebulas.io'

  switch (type) {
    case 'transaction': {
      return `${prefix}/${EXPLORER_PREFIXES[neb_chain_id]}tx/${data}`
    }

    case 'address':
    default: {
      return `${prefix}/${EXPLORER_PREFIXES[neb_chain_id]}address/${data}`
    }
  }
}

export function isValidAddress(address) {
  console.log('isValidAddress', address)
  return Account.isValidAddress(address)
}

export class Nebulas {
  constructor() {
    // init neb
    const neb = new Neb()
    neb.setRequest(new HttpRequest(host))

    // init nebpay
    const nebPay = new NebPay()

    this.neb = neb
    this.nebPay = nebPay
  }

  async getNebState() {
    try {
      const res = await this.neb.api.getNebState()
      const state = res.result || res
      console.log(state)
      return state
    } catch (err) {
      console.log('get Neb State error:', err)
    }
  }

  // get account NAS balance
  async getBalance(account) {
    try {

      if(account) {
        const res = await this.neb.api.getAccountState(account)
        const state = res.result || res
        const balance = state.balance
        console.log('getBalance', balance)
        return balance
      }

    } catch (err) {
      console.log('get balance error:', err)
    }
  }

  // read contract: call net function
  async read({ to, func, args }) {
    try {
      const res = await this.neb.api.call({
        chainID: chain_id,
        from: call_api_from,
        to,
        value: 0,
        gasPrice: GAS.PRICE,
        gasLimit: GAS.LIMIT,
        contract: {
          function: func,
          args: JSON.stringify(args)
        }
      })

      let result = JSON.parse(res.result);

      console.log('read contract', to, func, args, result)
      return result
    } catch (err) {
      console.log('read contract err',err, to, func, args)
    }
  }

  // write contract: use neb pay chrome extension to post function
  send({ to, func, args, value = 0 }) {
    return new Promise((resolve, reject) => {
      this.nebPay.call(to, value, func, JSON.stringify(args), {
        extension: {
          openExtension: true
        },
        gasPrice: GAS.PRICE,
        gasLimit: GAS.LIMIT_HIGH,
        // debug: true,
        listener: (serialNumber, resp) => {
          try {
            console.log('send result', serialNumber, resp.txhash)
            resolve(resp.txhash)
          } catch (err) {
            reject(err)
          }
        }
      })
    })
  }

  async getTransactionReceipt(txhash) {
    try {
      const result = await this.neb.api.getTransactionReceipt({
        hash: txhash
      })

      console.log('getTransactionReceipt', result.status)
      return result
    } catch (err) {
      console.log('getTransactionReceipt err', txhash)
    }
  }
}
