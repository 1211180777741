import React from 'react'
import styled from 'styled-components'

import nasLogo from 'assets/token/pair_nas.png'
import naxLogo from 'assets/token/pair_nax.png'
import nusdtLogo from 'assets/token/pair_nusdt.png'

const Image = styled.img`
  width: 50px;
  height: 50px;
`

const Pairs = styled.div`
  position: relative;
  height: 50px;

  img:nth-child(1) {
    position: absolute;
  }

  img:nth-child(2) {
    position: absolute;
    left: 40px;
  }
`

function getTokenLogoPath(symbol) {
  let path

  symbol = symbol.toLowerCase(symbol)

  switch (symbol) {
    case 'nas':
      path = nasLogo
      break
    case 'nax':
      path = naxLogo
      break
    case 'nusdt':
      path = nusdtLogo
      break

    default:
      path = nasLogo
      break
  }

  return path
}

export default function StakeTokenPair(props) {
  const pairsSymbols = props.pairs.split('-')
  return (
    <Pairs>
      <Image alt={pairsSymbols[0]} src={getTokenLogoPath(pairsSymbols[0])} />
      <Image alt={pairsSymbols[1]} src={getTokenLogoPath(pairsSymbols[1])} />
    </Pairs>
  )
}
