import { neb_chain_id, NEB_NETWORK } from './neb'

export class NoNebProviderError extends Error {
  constructor() {
    super()
    this.name = this.constructor.name
    this.message = 'No Neb extension wallet provider was found on window.NasExtWallet.'
  }
}

const getUserAddress = () =>
  new Promise((resolve, reject) => {
    if ('NasExtWallet' in window) {
      window.NasExtWallet.getUserAddress(addr => {
        resolve(addr)
      })
    } else {
      resolve(null)
    }
  })

export function getNebNetwork() {
  return { name: NEB_NETWORK[neb_chain_id], networkId: neb_chain_id }
}

export class nebWalletConnector {
  async activate() {
    // load from localStorage
    const nanoAddr = localStorage.getItem('nano')

    // if set nano address before
    if (nanoAddr) {
      const res = {
        provider: 'nano',
        account: nanoAddr
      }

      console.log('nas nano activate', res)

      return res
    } else {
      if (!window.NasExtWallet) {
        throw new NoNebProviderError()
      }
      const account = await getUserAddress()

      const res = Object.assign({ provider: null }, account ? { account } : {})

      console.log('neb chrome extension activate', res)

      return res
    }
  }

  async getProvider() {
    return window.NasExtWallet
  }
  // can't read chainID from neb ext wallet
  getChainId() {
    const { networkId } = getNebNetwork()
    return networkId
  }

  async getAccount() {
    if (!window.NasExtWallet) {
      throw new NoNebProviderError()
    }
    const account = await getUserAddress()
    return account
  }

  deactivate() {}
  async isAuthorized() {
    if (!window.NasExtWallet) {
      return false
    }
    return true
  }
}
