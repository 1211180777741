import React, { useState } from 'react'
import styled from 'styled-components'
import nasLogo from 'assets/images/nas.png'
import nanoLogo from 'assets/images/nanopro.png'
import googleLogo from 'assets/images/google.png'
import githubLogo from 'assets/images/github.png'
import { useTranslation } from 'react-i18next'

// modal
import Modal from 'components/Modal'
import { ReactComponent as Close } from 'assets/images/x.svg'
import { FiArrowLeft } from 'react-icons/fi'

import WalletCard from './components/WalletCard'
import AssociateAddressInput from './components/AssociateAddressInput'

// hooks
import { useWeb3React, useEagerConnect } from 'hooks'
import { injected } from 'connectors'

const WalletProviderModal = ({ isOpen, onDismiss, onConnectedCallback=()=>{} }) => {
  const { t } = useTranslation()
  const { account, useNano, activate } = useWeb3React();

  const [page, setPage] = useState('index')

    // useEffect(() => {
    //   if (useNano && account) {
    //     onDismiss()
    //     onConnectedCallback(account)
    //   }
    // }, [useNano, account, onDismiss, onConnectedCallback])

    const onSuccessLinkWallet = () => {
      onDismiss();
      onConnectedCallback();
    }

    const hanldeClickChromeExtension = async () => {

        if(injected) {

           // remove nas nano localStorage
           localStorage.removeItem('nano');

            const acc = await activate(injected);
            if (acc) {
              console.log('connected', acc);

              // close modal
              onSuccessLinkWallet();
            } 
        }
        // not install chrome extension
        setPage('install-chrome-extension')

    }

  const handleBackToIndex = () => {
    setPage('index')
  }

  const handleUseNasnano = () => {
    setPage('use-nas-nano')
  }

  // installed nas ext wallet, detect select wallet file, can't read account
  const triedEager = useEagerConnect()

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} width={40} maxHeight={false}>
      <ModalContainer>
        <ModalHeader>
          {page === 'index' ? (
            t('select-an-nebulas-wallet')
          ) : (
            <StyledBack onClick={handleBackToIndex}>
              <FiArrowLeft />
            </StyledBack>
          )}

          <CloseIcon onClick={onDismiss}>
            <CloseColor alt={'close icon'} />
          </CloseIcon>
        </ModalHeader>

        <Container>
          {page === 'index' && (
            <StyledWalletsWrapper>
              <StyledWalletCard>
                <WalletCard icon={nasLogo} onConnect={hanldeClickChromeExtension} title={t('nebulas-chrome-extension')} extraMsg={
                !triedEager && t('unlockNasWalletShort')
                } />
              </StyledWalletCard>

              <StyledWalletCard>
                <WalletCard icon={nanoLogo} onConnect={handleUseNasnano} title="Nas Nano Pro" />
              </StyledWalletCard>
            </StyledWalletsWrapper>
          )}

          {page === 'install-chrome-extension' && (
            <>
              <StyledWalletCard>
                <WalletCard
                  icon={googleLogo}
                  href="https://chrome.google.com/webstore/detail/nasextwallet/gehjkhmhclgnkkhpfamakecfgakkfkco"
                  title={t("google-store-download")}
                />
              </StyledWalletCard>

              <StyledWalletCard>
                <WalletCard
                  icon={githubLogo}
                  href="https://github.com/nebulasio/WebExtensionWallet/releases"
                  title={t("github-download")}
                />
              </StyledWalletCard>
            </>
          )}

          {page === 'use-nas-nano' && <AssociateAddressInput onAssociate={onSuccessLinkWallet} />}
        </Container>
      </ModalContainer>
    </Modal>
  )
}

const ModalContainer = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  width: 100%;
`

const ModalHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 0px 1rem;
  height: 60px;
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.textColor};
  }
`

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const Container = styled.div`
  padding: 20px;
  border-radius: 0;
  border-top: 1px solid ${({ error, theme }) => (error ? theme.salmonRed : theme.mercuryGray)};
  background-color: ${({ theme }) => theme.inputBackground};
`

const StyledWalletsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledWalletCard = styled.div`
  padding: 6px 0;
`

const StyledBack = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.text2};
  cursor: pointer;
`

const StyledAddressInputWrapper = styled.div`
  padding: 20px;
`

export default WalletProviderModal
