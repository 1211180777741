import React from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { transparentize, darken } from 'polished'
import styled from 'styled-components'
import Container from 'components/Container'

const NavList = styled.div`
  display: flex;
  margin-bottom: 3rem;
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  margin: 0 2rem;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.doveGray};
  font-size: 18px;

  &.${activeClassName} {
    font-weight: 500;
    color: #000;
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, '#000')};
  }
`

const navList = [
  {
    path: '/swap',
    textKey: 'Swap',
    regex: /\/swap|send|add-liquidity|remove-liquidity/
  },
  {
    path: '/stake',
    textKey: 'Stake',
    regex: /\/stake/
  }
]

const Nav = () => {
  return (
    <Container>
      <NavList>
        {navList.map(({ path, textKey, regex }) => (
          <StyledNavLink key={path} to={path} isActive={(_, { pathname }) => pathname.match(regex)}>
            {textKey}
          </StyledNavLink>
        ))}
      </NavList>
    </Container>
  )
}

export default Nav
