import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { darken } from 'polished'

import { Button } from 'theme'
import { amountFormatter } from 'utils'
import { basicToNas } from 'utils/nebFormat'
import { bigNumberify } from 'ethers/utils'

import StakeTokenPair from 'components/StakeTokenPair'
import StakeModal from 'components/StakeModal'

import { useAddressBalance } from 'contexts/Balances'
// import { useSwapReserves } from 'hooks/useSwapReserves'
import { useAddressAllowance } from 'contexts/Allowances'
import { getTokenSupply, getAllowanceAndBalnce,  approveToken } from 'utils/nebUtil'
import { useTransactionAdder, usePendingApproval, useAllTransactions } from 'contexts/Transactions'

import { usePoolClaim } from 'hooks/usePoolClaim'
import { useGetPool } from 'hooks/useGetPool'

// neb
import { useWeb3React } from 'hooks'

// modal
import { useModal } from 'hooks/useModal'
import CallContractModal from 'components/CallContractModal'

const CardContainer = styled.div`
  margin: 0 15px;
  width: 33%;
  min-height: 300px;
  box-shadow: rgba(134, 141, 167, 0.15) 0px 0px 10px 0px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    margin: 0 0 3rem 0;
  `};
`

const Card = styled.div`
  padding: 30px 40px;
  background-color: #fff;
`

const Header = styled.div`
  margin-bottom: 30px;
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Info = styled.div`
  width: 50%;
  label {
    font-size: 14px;
  }

  span {
  }

  p {
    font-weight: bold;
    font-size: 16px;
  }
`

const RewardRate = styled.span`
  color: #1ba27a;
  font-size: 14px;
  margin-left: 5px;
  font-weight: 500;
`

const Flex = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 2rem;

  button {
    max-width: 20rem;
  }
`

const Staked = styled.div`
  padding: 25px 30px;
  background-color: #f7f6f8;

  p {
    font-size: 14px;
  }
`

const StakedActions = styled.div`
  display: flex;
  justify-content: space-between;
`

export const BorderButton = styled.button.attrs(({ warning, theme }) => ({
  backgroundColor: warning ? theme.salmonRed : theme.royalBlue
}))`
  padding: 6px 20px;
  cursor: pointer;
  user-select: none;
  font-size: 1rem;
  border: none;
  outline: none;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.primary.main};
  color: ${({ theme }) => theme.primary.main};

  :hover,
  :focus {
    background-color: ${({ backgroundColor }) => darken(0.05, backgroundColor)};
    color: ${({ theme }) => theme.white};
  }

  :active {
    background-color: ${({ backgroundColor }) => darken(0.1, backgroundColor)};
  }

  :disabled {
    background-color: ${({ theme }) => theme.concreteGray};
    color: ${({ theme }) => theme.silverGray};
    cursor: auto;
  }
`

export const LinkButton = styled.button.attrs(({ warning, theme }) => ({
  backgroundColor: warning ? theme.salmonRed : theme.royalBlue
}))`
  padding: 6px 20px;
  cursor: pointer;
  user-select: none;
  font-size: 1rem;
  border: none;
  outline: none;
  background-color: transparent;
  color: ${({ theme }) => theme.primary.main};

  :hover,
  :focus {
    color: ${({ backgroundColor }) => darken(0.05, backgroundColor)};
  }

  :active {
    color: ${({ backgroundColor }) => darken(0.1, backgroundColor)};
  }

  :disabled {
    color: ${({ theme }) => theme.silverGray};
    cursor: auto;
  }
`

const StakeCard = ({ user_info, pool_address, account, data }) => {
  const { t } = useTranslation()

  const { useNano } = useWeb3React()
  const { id, deposit, earn, rate, tokens, lp } = data

  const [modalType, setModalType] = useState('stake')
  const [modalIsOpen, setModalIsOpen] = useState(false)

  // get wallet account, pool token(lp token) balance
  const poolTokenBalance = useAddressBalance(account, lp)
  const poolTokenAllowance = useAddressAllowance(account, lp, pool_address)

  // lp token approval
  const pendingApproval = usePendingApproval(lp)

  // pool info
  const { pairInfo } = useGetPool(id)

  const [showUnlock, setShowUnlock] = useState(false);
  const [canStake, setCanStake] = useState(false);
  const [stakeErr, setStakeErr] = useState();

  const { onClaim, getContractParams: getClaimContractParams } = usePoolClaim()

   // show claim reward qrcode modal
   const [onShowClaimModal] = useModal(<CallContractModal title={t('claim')} />)


  useEffect(() => {
    try {
      if (poolTokenAllowance && poolTokenBalance) {
        // console.log('setShowUnlock', {
        //   id,
        //   deposit,
        //   poolTokenAllowance: poolTokenAllowance.toString(),
        //   poolTokenBalance: poolTokenBalance.toString()
        // })

        if (poolTokenAllowance.lt(poolTokenBalance)) {
          setShowUnlock(true)
        } else {
          setShowUnlock(false);
          setCanStake(true);
        }

        if(poolTokenBalance.isZero()) {
          setCanStake(false);
          setStakeErr(t('insufficientBalance'));
        }

        return () => {
          setShowUnlock(false)
          setStakeErr();
        }
      }
    } catch (err) {
      console.log('setShowUnlock err', { poolTokenAllowance, poolTokenBalance })
    }
  }, [poolTokenAllowance, poolTokenBalance])

  const addTransaction = useTransactionAdder()

  function toggleModal(type) {
    setModalType(type)
    setModalIsOpen(!modalIsOpen)
  }


    // show unlock(approve) qrcode modal
    const [onShowUnlockModal] = useModal(<CallContractModal title={t('unlock')} />)

    const handleClickUnlock = async () => {
      const spenderAddress = pool_address
      const lpTokenAddress = lp;

      if (useNano) {
        const { allowance, balance } = await getAllowanceAndBalnce(account, lpTokenAddress, spenderAddress)
  
        onShowUnlockModal({
          to: lpTokenAddress,
          func: 'approve',
          args: [spenderAddress, allowance, balance]
        })
      } else {
        await approveToken(account, lpTokenAddress, spenderAddress)
          .then(txhash => {
            addTransaction(txhash, { approval: lpTokenAddress })
          })
          .catch(err => {
            console.error('Error during approveToken() call', err)
          })
      }
    }



  // render unlock lp token button
  const renderUnlockButton = () => {
    if (!showUnlock || !lp) {
      return null
    } else {
      if (!pendingApproval) {
        return (
          <Button
            onClick={handleClickUnlock}
          >
            {t('unlock')} {deposit} LP
          </Button>
        )
      } else {
        return <Button disabled={true}>{t('pending')}</Button>
      }
    }
  }

  async function onClickClaim() {

    if(useNano) {
      const contractParams = getClaimContractParams(id);
      onShowClaimModal(contractParams);

    } else {
      try {
        const txhash = await onClaim(id)
        addTransaction(txhash)
      } catch (err) {
        console.log('onClaim error', err)
      }
    }


  }

  const showUnstake = user_info && user_info.amount > 0 ? true : false;
  const showClaim = user_info && user_info.pendingToken > 0 ? true : false;


  return (
    <CardContainer>
      <Card>
        <Header>
          <StakeTokenPair pairs={deposit} />
        </Header>
        <Content>
          <Info>
            <label>{t('deposit')}</label>
            <p>{deposit}</p>
          </Info>

          <Info>
            <label>{t('earn')}</label>
            <p>
              {earn}
              <RewardRate>{t('xReward', { rate })}</RewardRate>
            </p>
          </Info>

          <Info>
            <label>{t('stakingInPool')}</label>
            <p>{pairInfo ? `$${amountFormatter(bigNumberify(pairInfo.value.total), 6)}` : '--'}</p>
          </Info>

          <Info>
            <label>{t('APY')}</label>
            <p>{pairInfo ? `${(Number(amountFormatter(bigNumberify(pairInfo.value.apy), 4))* rate / 6).toFixed(2)}%` : '--'}</p>
          </Info>
        </Content>

        <Flex>
          {renderUnlockButton()}
          {!showUnlock && <Button disabled={!canStake} onClick={() => toggleModal('stake')}>{ stakeErr ? stakeErr : t('stakeNow')}</Button>}
        </Flex>
      </Card>
      <Staked>
        <p>
          {t('yourStaked')}:{' '}
          {user_info && user_info.amount ? amountFormatter(bigNumberify(user_info.amount), 18, 12) : '0'} {deposit} LP
        </p>
      
        <p>
          {t('waitToClaim')}:{' '}
          {user_info && user_info.pendingToken ? amountFormatter(bigNumberify(user_info.pendingToken), 18, 4) : '0'}{' '}
          {earn}
        </p>

        <StakedActions>
          {showClaim ? <BorderButton onClick={onClickClaim}>{t('claim')}</BorderButton> : <div></div>}

          {showUnstake ? <LinkButton onClick={() => toggleModal('unstake')}>{t('unstake')}</LinkButton> : <div></div>}
        </StakedActions>
      </Staked>
      <StakeModal
        poolId={id}
        type={modalType}
        balance={poolTokenBalance}
        staked={user_info && user_info.amount}
        lpPair={deposit}
        isOpen={modalIsOpen}
        onDismiss={() => setModalIsOpen(false)}
      />
    </CardContainer>
  )
}

export default StakeCard
