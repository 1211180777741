import { useReducer, useEffect, useCallback, useRef } from 'react'
import warning from 'tiny-warning'
import { getNebNetwork } from 'utils/nebWalletConnector'

class StaleConnectorError extends Error {
  constructor() {
    super()
    this.name = this.constructor.name
  }
}

export class UnsupportedChainIdError extends Error {
  constructor(unsupportedChainId, supportedChainIds) {
    super()
    this.name = this.constructor.name
    this.message = `Unsupported chain id: ${unsupportedChainId}. Supported chain ids are: ${supportedChainIds}.`
  }
}

const ActionType = {
  ACTIVATE_CONNECTOR: 'ACTIVATE_CONNECTOR',
  UPDATE: 'UPDATE',
  UPDATE_FROM_ERROR: 'UPDATE_FROM_ERROR',
  ERROR: 'ERROR',
  ERROR_FROM_ACTIVATION: 'ERROR_FROM_ACTIVATION',
  DEACTIVATE_CONNECTOR: 'DEACTIVATE_CONNECTOR'
}

function reducer(state, { type, payload }) {
  switch (type) {
    case ActionType.ACTIVATE_CONNECTOR: {
      const { connector, provider, chainId, account, onError } = payload
      console.log('ACTIVATE_CONNECTOR', { connector, provider, chainId, account, onError })
      return { connector, provider, chainId, account, onError }
    }
    case ActionType.UPDATE: {
      const { provider, chainId, account } = payload
      return {
        ...state,
        ...(provider === undefined ? {} : { provider }),
        ...(chainId === undefined ? {} : { chainId }),
        ...(account === undefined ? {} : { account })
      }
    }
    case ActionType.UPDATE_FROM_ERROR: {
      const { provider, chainId, account } = payload
      return {
        ...state,
        ...(provider === undefined ? {} : { provider }),
        ...(chainId === undefined ? {} : { chainId }),
        ...(account === undefined ? {} : { account }),
        error: undefined
      }
    }
    case ActionType.ERROR: {
      const { error } = payload
      const { connector, onError } = state
      return {
        connector,
        error,
        onError
      }
    }
    case ActionType.ERROR_FROM_ACTIVATION: {
      const { connector, error } = payload
      return {
        connector,
        error
      }
    }
    case ActionType.DEACTIVATE_CONNECTOR: {
      return {}
    }
    default: {
      return {}
    }
  }
}

async function augmentConnectorUpdate(connector, update) {
  const provider = update.provider === undefined ? await connector.getProvider() : update.provider

  const network = getNebNetwork();

  const [chainId, account] = await Promise.all([
    update.chainId === undefined ? network.networkId : update.chainId,
    update.account === undefined ? connector.getAccount() : update.account
  ])

  // if (!!connector.supportedChainIds && !connector.supportedChainIds.includes(chainId)) {
  //   throw new UnsupportedChainIdError(chainId, connector.supportedChainIds)
  // }

  return { provider, chainId, account }
}

export function useWeb3ReactManager() {
  const [state, dispatch] = useReducer(reducer, {})
  const { connector, provider, chainId, account, onError, error } = state

  const updateBusterRef = useRef(-1)
  updateBusterRef.current += 1

  // connect to nas nano
  const connectNano = useCallback((address) => {
    const network = getNebNetwork();
    const update = {
      provider: 'nano',
      chainId: network.networkId,
      account: address
    }

    // const augmentedUpdate = await augmentConnectorUpdate(connector, update)
    const augmentedUpdate = update;

    // save to localStorage, nano address
    localStorage.setItem("nano", address);

    console.log('connect to nano', augmentedUpdate)
    dispatch({ type: ActionType.ACTIVATE_CONNECTOR, payload: {  ...augmentedUpdate } })
  }, [])

  const activate = useCallback(async (connector, onError, throwErrors = false) => {



    const updateBusterInitial = updateBusterRef.current

    let activated = false
    try {
      const update = await connector.activate().then(update => {
        activated = true
        return update
      })

      const augmentedUpdate = await augmentConnectorUpdate(connector, update)

      if (updateBusterRef.current > updateBusterInitial) {
        throw new StaleConnectorError()
      }
      dispatch({ type: ActionType.ACTIVATE_CONNECTOR, payload: { connector, ...augmentedUpdate, onError } })

      return augmentedUpdate.account;
    } catch (error) {
      // if (error instanceof StaleConnectorError) {
      //   activated && connector.deactivate()
      //   warning(false, `Suppressed stale connector activation ${connector}`)
      // } else if (throwErrors) {
      //   activated && connector.deactivate()
      //   throw error
      // } else if (onError) {
      //   activated && connector.deactivate()
      //   onError(error)
      // } else {
      //   // we don't call activated && connector.deactivate() here because it'll be handled in the useEffect
      //   dispatch({ type: ActionType.ERROR_FROM_ACTIVATION, payload: { connector, error } })
      // }
    }
  }, [])

  const setError = useCallback(error => {
    dispatch({ type: ActionType.ERROR, payload: { error } })
  }, [])

  const deactivate = useCallback(() => {
    dispatch({ type: ActionType.DEACTIVATE_CONNECTOR })
  }, [])

  // ensure that connectors which were set are deactivated
  useEffect(() => {
    return () => {
      if (connector) {
        connector.deactivate()
      }
    }
  }, [connector])

  return { connector, provider, chainId, account, activate, setError, deactivate, error, connectNano }
}
