import { injected } from '../connectors'

// import addresses from '@oikos/swap/addresses.json'
import addresses from './addresses.json'

// deploy.js output in uniswap contracts repo

// export const FACTORY_ADDRESSES = {
//   1: toEthAddress(addresses.shasta.factory),
//   2: toEthAddress(addresses.shasta.factory)
// }

export const SWAP_ADDRESSES = {
  1: addresses.mainnet.swap,
  1001: addresses.testnet.swap
}

export const POOL_ADDRESS = {
  1: addresses.mainnet.pool,
  1001: addresses.testnet.pool
}

export const LP_ADDRESSES = {
  1: addresses.mainnet.lp,
  1001: addresses.testnet.lp
}

export const TOKEN_ADDRESSES = {
  1: addresses.mainnet.exchanges,
  1001: addresses.testnet.exchanges
}

export const SUPPORTED_THEMES = {
  DARK: 'DARK',
  LIGHT: 'LIGHT'
}

export const NAME = 'name'
export const SYMBOL = 'symbol'
export const DECIMALS = 'decimals'
export const EXCHANGE_ADDRESS = 'exchangeAddress'
export const SETUP = 'setup'

// const synthWhitelist = ['sUSD', 'sTRX', 'OKS']
// const otherTokensWhitelist = ['USDJ', 'JST', 'TEWKEN', 'DCD', 'BNKR', 'sETH', 'CSX', 'MIMA', 'CNS']

export const MAIN_TOKEN = 'NAS'

const linkWhiteList = ['nUSDT']
const baseTokensWhiteList = ['wNAS', 'NAX']

//const secretWhitelist = localStorage.getItem('development') ? ['CNS'] : []

// const exchangeWhitelist = [...synthWhitelist, ...otherTokensWhitelist]
const exchangeWhitelist = [...linkWhiteList, ...baseTokensWhiteList]

const buildTokens = addrs => {
  return Object.keys(addrs.exchanges)
    .filter(symbol => exchangeWhitelist.includes(symbol))
    .map(symbol => {
      const { address, decimals = 18, setup, name } = addrs.exchanges[symbol]
      return [
        address,
        {
          [NAME]: name,
          [SYMBOL]: symbol,
          [DECIMALS]: decimals,
          [EXCHANGE_ADDRESS]: address,
          [SETUP]: setup
        }
      ]
    })
    .reduce((acc, [address, obj]) => {
      return { ...acc, [address]: obj }
    }, {})
}

export const INITIAL_TOKENS_CONTEXT = {
  1: buildTokens(addresses.mainnet),
  1001: buildTokens(addresses.testnet)
}
// console.log('INITIAL_TOKENS_CONTEXT', INITIAL_TOKENS_CONTEXT)

export const SUPPORTED_WALLETS = {
  TRONLINK: {
    connector: injected, // todo
    name: 'Nabulas Wallet',
    iconName: 'nas.png',
    description: 'Easy-to-use browser extension.',
    href: 'https://github.com/nebulasio/WebExtensionWallet',
    color: '#4196FC'
  }
}

// list of tokens that lock fund on adding liquidity - used to disable button
export const brokenTokens = []

// export const NetworkContextName = 'NETWORK'
