import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Box, Text } from 'rebass/styled-components'
import { useTranslation } from 'react-i18next'
// utils
import { isNebValidAddress } from 'utils/nebFormat'

const AddressInput = ({ border = false, value = '', onChange = () => {}, setInvalid = () => {} }) => {
  const { t } = useTranslation()
  const [errorMsg, setErrorMsg] = useState('')

  const onInputChange = e => {
    const value = e.target.value
    onChange(value)

    const invalid = checkRecpInputValid(value)

    setInvalid(invalid)
  }


  const checkRecpInputValid = addr => {
    let invalid = false
    setErrorMsg('')

    console.log('checkRecpInputValid', addr, isNebValidAddress(addr))

    if (!addr) {
      invalid = true
      setErrorMsg(t('please-input-nebulas-address'))
    } else if (!isNebValidAddress(addr)) {
      invalid = true
      setErrorMsg(t('nebulas-address-placeholder'))
    }

    return invalid
  }

  return (
    <StyleWrapper border={border}>
      <StyledContent>
        <StyledInput
          type="text"
          value={value}
          onChange={e => onInputChange(e)}
          placeholder={t('nebulas-address-placeholder')}
        ></StyledInput>

        {errorMsg ? <StyledError>{errorMsg}</StyledError> : null}
      </StyledContent>
    </StyleWrapper>
  )
}

const StyleWrapper = styled(Flex)`
  flex-direction: column;
  border: ${props => (props.border ? `1px solid ${props.theme.border1}` : 'none')};
`

const StyledContent = styled(Flex)`
  padding-top: 12px;
  flex-direction: column;
`

const StyledInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  font-size: 14px;
  color: ${({ theme }) => theme.text1};

  &::placeholder {
    color: #999;
  }

  &:disabled {
    background-color: transparent;
  }
`

const StyledError = styled(Text)`
  color: red;
  font-size: 13px;
`

export default AddressInput
