import React, { useEffect } from 'react'
import styled from 'styled-components'

import Container from 'components/Container'
import StakeCard from 'components/StakeCard'

import { useWeb3React } from 'hooks'
import { MAIN_TOKEN, TOKEN_ADDRESSES, LP_ADDRESSES, POOL_ADDRESS } from '../../constants'

import { useGetAccountPool } from 'hooks/useGetAccountPool'

// styles
const CardContainer = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-wrap: wrap;
`};
`

const Stake = () => {
  const { chainId, account } = useWeb3React()
  const lp_addresses = LP_ADDRESSES[chainId]
  const token_addresses = TOKEN_ADDRESSES[chainId]
  const pool_address = POOL_ADDRESS[chainId]

  // vars
  const STAKE_POOLS = [
    {
      id: 0,
      deposit: 'NAX-NAS',
      rate: 3,
      earn: 'NAS',
      tokens: [token_addresses['NAX'], MAIN_TOKEN],
      lp: lp_addresses['NAX-NAS']
    },
    {
      id: 1,
      deposit: 'nUSDT-NAX',
      rate: 2,
      earn: 'NAS',
      tokens: [token_addresses['nUSDT'], token_addresses['NAX']],
      lp: lp_addresses['nUSDT-NAX']
    },
    {
      id: 2,
      deposit: 'nUSDT-NAS',
      rate: 1,
      earn: 'NAS',
      tokens: [token_addresses['nUSDT'], MAIN_TOKEN],
      lp: lp_addresses['nUSDT-NAS']
    }
  ]

  const { userInfo } = useGetAccountPool()

  return (
    <Container>
      <CardContainer>
        {STAKE_POOLS.map(item => (
          <StakeCard
            key={item.id}
            user_info={userInfo[item.id]}
            pool_address={pool_address}
            account={account}
            data={item}
          />
        ))}
      </CardContainer>
    </Container>
  )
}

export default Stake
