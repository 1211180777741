import React from 'react'
import ReactDOM from 'react-dom'

// providrs
import { Web3ReactProvider } from 'contexts/web3'
import LocalStorageContextProvider, { Updater as LocalStorageContextUpdater } from './contexts/LocalStorage'
import ApplicationContextProvider, { Updater as ApplicationContextUpdater } from './contexts/Application'
import TransactionContextProvider, { Updater as TransactionContextUpdater } from './contexts/Transactions'
import BalancesContextProvider, { Updater as BalancesContextUpdater } from './contexts/Balances'
import TokensContextProvider from './contexts/Tokens'
import AllowancesContextProvider from './contexts/Allowances'
import App from './pages/App'
import ThemeProvider, { GlobalStyle } from './theme'
import ModalsProvider from 'contexts/Modals'

import promiseRetry from 'promise-retry'
import './i18n'
import { Nebulas } from 'utils/neb'
import { EventEmitter } from 'events'

function getLibrary() {
  const neb = new Nebulas()

  const getBlockNumber = async () => {
    const state = await neb.getNebState()
    const blockHeight = Number(state.height)
    return blockHeight
  }

  const emitter = new EventEmitter()
  const on = (...args) => {
    emitter.on(...args)
  }
  const removeListener = (...args) => {
    emitter.removeListener(...args)
  }

  const lookupAddress = async (...args) => {
    console.warn('library.lookupAddress() not implemented', args)
  }

  const getTokenBalance = async (tokenAddress, address) => {
    try {
      // get neb account balance
      const balance = await neb.read({
        to: tokenAddress,
        func: 'balanceOf',
        args: [address]
      })

      // fix get balance has comma
      return balance.split('.')[0]
    } catch (err) {
      console.error('error getting balance', { tokenAddress, address })
      throw err
    }
  }

  const toTronAddr = str => {
    return str
  }

  // poll for new blocks
  let lastBlockNumber

  setInterval(async () => {
    // query new block
    const blockNumber = await promiseRetry(async retry => {
      try {
        const height = await getBlockNumber()
        if (!height) {
          return retry()
        }
        return height
      } catch (err) {
        console.error('getBlockNumber error', err)
        return retry()
      }
    })
    if (lastBlockNumber !== blockNumber) {
      // console.log(`new block ${blockNumber}`, block)
      lastBlockNumber = blockNumber
      emitter.emit('block', blockNumber)
    }
  }, 15 * 1000)

  return {
    provider: null,
    getBlockNumber,
    getTokenBalance,
    on,
    lookupAddress,
    removeListener,
    toTronAddr
  }
}

function ContextProviders({ children }) {
  return (
    <LocalStorageContextProvider>
      <ApplicationContextProvider>
        <TransactionContextProvider>
          <TokensContextProvider>
            <BalancesContextProvider>
              <AllowancesContextProvider>{children}</AllowancesContextProvider>
            </BalancesContextProvider>
          </TokensContextProvider>
        </TransactionContextProvider>
      </ApplicationContextProvider>
    </LocalStorageContextProvider>
  )
}

function Updaters() {
  return (
    <>
      <LocalStorageContextUpdater />
      <ApplicationContextUpdater />
      <TransactionContextUpdater />
      <BalancesContextUpdater />
    </>
  )
}

ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <ContextProviders>
      <Updaters />
      <ThemeProvider>
        <>
          <GlobalStyle />
          <ModalsProvider>
            <App />
          </ModalsProvider>
        </>
      </ThemeProvider>
    </ContextProviders>
  </Web3ReactProvider>,
  document.getElementById('root')
)
