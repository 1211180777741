import { useCallback } from 'react'
import { useWeb3React } from '.'
import { POOL_ADDRESS } from '../constants'
import { Nebulas } from 'utils/neb'

// claim stake reward
const usePoolClaim = () => {
  const { chainId } = useWeb3React()

  const stake_address = POOL_ADDRESS[chainId]

  const getContractParams = poolId => {
    return {
      to: stake_address,
      func: 'claim',
      args: [poolId],
      value: 0
    }
  }

  const onClaim = useCallback(async poolId => {
    const neb = new Nebulas()

    try {
      const { to, func, args, value } = getContractParams(poolId)

      const txhash = await neb.send({
        to,
        func,
        args,
        value
      })

      return txhash
    } catch (err) {
      console.log('onClaim error', err)
    }
  }, [])

  return {
    onClaim,
    getContractParams
  }
}

export { usePoolClaim }
