import { useState, useCallback } from 'react'
// import { ethers } from 'ethers'
import { useWeb3React } from '.'
import { MAIN_TOKEN, SWAP_ADDRESSES } from '../constants'
import { Nebulas } from 'utils/neb'
// import { formatNasBalance } from 'utils'
// const { bigNumberify } = ethers.utils

const INPUT = 0
const OUTPUT = 1

const NAS_TO_TOKEN = 0
const TOKEN_TO_NAS = 1
const TOKEN_TO_TOKEN = 2

// swap tokens
const useSwap = () => {
  const { account, chainId } = useWeb3React()

  const swap_address = SWAP_ADDRESSES[chainId]

  const getContractParams = useCallback(({ swapType, independentField, path, toAddress, amountIn, amountOut, nasValue }) => {
    // set toAddress default to wallet account
    toAddress = toAddress || account
    nasValue = nasValue || 0
    let func, args

    // nas => token
    if (swapType === NAS_TO_TOKEN) {
      if (independentField === INPUT) {
        func = 'swapExactNASForTokens'
        args = [amountOut, path, toAddress]
      } else if (independentField === OUTPUT) {
        func = 'swapNASForExactTokens'
        args = [amountOut, path, toAddress]
      }
    }

    // token => nas
    if (swapType === TOKEN_TO_NAS) {
      if (independentField === INPUT) {
        func = 'swapExactTokensForNAS'
        args = [amountIn, amountOut, path, toAddress]
      } else if (independentField === OUTPUT) {
        func = 'swapTokensForExactNAS'
        args = [amountOut, amountIn, path, toAddress]
      }
    }

    // token => token
    if (swapType === TOKEN_TO_TOKEN) {
      if (independentField === INPUT) {
        func = 'swapExactTokensForTokens'
        args = [amountIn, amountOut, path, toAddress]
      } else if (independentField === OUTPUT) {
        func = 'swapTokensForExactTokens'
        args = [amountOut, amountIn, path, toAddress]
      }
    }

    console.log('onSwap', func, args, nasValue)

    return  {
      to: swap_address,
      func,
      args,
      value: nasValue
    }
  },[account, swap_address]);

  const onSwap = useCallback(
    async ({ swapType, independentField, path, toAddress, amountIn, amountOut, nasValue }) => {
      
      const neb = new Nebulas()

      const contractParams = getContractParams({ swapType, independentField, path, toAddress, amountIn, amountOut, nasValue })

      try {

        const txhash = await neb.send({
          ...contractParams
        })

        return txhash
      } catch (err) {
        console.log('on swap error', err)
      }
    },
    [getContractParams]
  )

  return {
    onSwap, getContractParams
  }
}

export { useSwap }
