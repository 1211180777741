import { useState, useCallback, useEffect } from 'react'
import { useWeb3React } from '.'
import { POOL_ADDRESS } from '../constants'
import { Nebulas } from 'utils/neb'

// get stake pool info
const useGetAccountPool = () => {
  const { account, chainId } = useWeb3React()
  const [userInfo, setUserInfo] = useState({
    '0': { amount: '0', rewardDebt: '0' },
    '1': { amount: '0', rewardDebt: '0' },
    '2': { amount: '0', rewardDebt: '0' }
  })

  const stake_address = POOL_ADDRESS[chainId]

  const fetchUserPoolInfo = useCallback(async () => {
    const neb = new Nebulas()

    if (!account) {
      return
    }
    try {
      const func = 'getUserInfo'
      const args = [account]

      const result = await neb.read({
        to: stake_address,
        func,
        args
      })

      if (result) {
        setUserInfo(result)
      }

      console.log('fetchUserPoolInfo', func, args, result)
    } catch (err) {
      console.log('fetchUserPoolInfo error', err)
    }
  }, [account, stake_address])

  useEffect(() => {

    fetchUserPoolInfo();
    
    const timer = setInterval(() => fetchUserPoolInfo(), 5*1000);
    return () => {
      clearInterval(timer);
    }
  }, [fetchUserPoolInfo])

  return {
    userInfo, fetchUserPoolInfo
  }
}

export { useGetAccountPool }
