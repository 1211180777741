import { useCallback, useContext } from 'react'

import { Context } from '../contexts/Modals/Modals'

const useModal = (modal) => {
  const { onDismiss, onPresent } = useContext(Context)

  const handlePresent = useCallback((params) => {
    onPresent(modal, params)
  }, [modal, onPresent])

  return [handlePresent, onDismiss]
}

export { useModal }
