import React from 'react'
import ExchangePage from '../../components/ExchangePage'
import { Body } from "theme/components";

export default function Swap({ initialCurrency, params }) {
  return (
    <Body>
      <ExchangePage initialCurrency={initialCurrency} params={params} />
    </Body>
  )
}
