import React, { createContext, useContext, useReducer, useMemo, useCallback, useEffect } from 'react'
// import {} from '@web3-react/core'

import { useWeb3React } from '../hooks'
import { safeAccess, isAddress } from '../utils'
import { getTokenAllowance } from '../utils/nebUtil'
import { useBlockNumber } from './Application'
import { MAIN_TOKEN, SWAP_ADDRESSES } from '../constants'

const UPDATE = 'UPDATE'

const AllowancesContext = createContext()

function useAllowancesContext() {
  return useContext(AllowancesContext)
}

function reducer(state, { type, payload }) {
  switch (type) {
    case UPDATE: {
      const { networkId, address, tokenAddress, spenderAddress, value, blockNumber } = payload

      const newState = {
        ...state,
        [networkId]: {
          ...(safeAccess(state, [networkId]) || {}),
          [address]: {
            ...(safeAccess(state, [networkId, address]) || {}),
            [tokenAddress]: {
              ...(safeAccess(state, [networkId, address, tokenAddress]) || {}),
              [spenderAddress]: {
                value,
                blockNumber
              }
            }
          }
        }
      }

      // console.log('update useAllowancesContext', newState)

      return newState
    }
    default: {
      throw Error(`Unexpected action type in AllowancesContext reducer: '${type}'.`)
    }
  }
}

export default function Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, {})

  const update = useCallback((networkId, address, tokenAddress, spenderAddress, value, blockNumber) => {
    dispatch({ type: UPDATE, payload: { networkId, address, tokenAddress, spenderAddress, value, blockNumber } })
  }, [])

  return (
    <AllowancesContext.Provider value={useMemo(() => [state, { update }], [state, update])}>
      {children}
    </AllowancesContext.Provider>
  )
}

export function useAddressAllowance(address, tokenAddress, spenderAddress) {
  const { chainId } = useWeb3React()

  const globalBlockNumber = useBlockNumber()

  const swap_address = SWAP_ADDRESSES[chainId]
  spenderAddress = spenderAddress || swap_address

  const [state, { update }] = useAllowancesContext()
  const { value, blockNumber } = safeAccess(state, [chainId, address, tokenAddress, spenderAddress]) || {}

  // console.log('useAddressAllowance', {
  //   address,
  //   tokenAddress,
  //   spenderAddress
  // })

  const fetchAllowance = useCallback(async () => {
    console.log('fetchAllowance', { chainId, address, tokenAddress, spenderAddress, blockNumber })

    if (isAddress(address) && isAddress(tokenAddress) && isAddress(spenderAddress)) {

      try {
        const allowance = await getTokenAllowance(address, tokenAddress, spenderAddress)

        console.log('useAddressAllowance value', { address, tokenAddress, spenderAddress, allowance: allowance.toString() })
  
        update(chainId, address, tokenAddress, spenderAddress, allowance, blockNumber)
  
        return allowance
      } catch(err) {
        console.log("fetchAllowance err")
      }

    }
  }, [address, tokenAddress, spenderAddress, blockNumber, chainId, update])

  useEffect(() => {
    if (address && tokenAddress && spenderAddress && globalBlockNumber) {
      fetchAllowance()
    }
  }, [address, tokenAddress, spenderAddress, fetchAllowance, globalBlockNumber])

  return value
}
