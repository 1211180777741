import { ethers } from 'ethers'

import { SUPPORTED_THEMES } from '../constants'
import { formatFixed } from '@uniswap/sdk'

import { MAIN_TOKEN } from '../constants'
const { bigNumberify } = ethers.utils

export const ERROR_CODES = ['TOKEN_NAME', 'TOKEN_SYMBOL', 'TOKEN_DECIMALS'].reduce(
  (accumulator, currentValue, currentIndex) => {
    accumulator[currentValue] = currentIndex
    return accumulator
  },
  {}
)

export function safeAccess(object, path) {
  return object
    ? path.reduce(
        (accumulator, currentValue) => (accumulator && accumulator[currentValue] ? accumulator[currentValue] : null),
        object
      )
    : null
}

export function getQueryParam(windowLocation, name) {
  var q = windowLocation.search.match(new RegExp('[?&]' + name + '=([^&#?]*)'))
  return q && q[1]
}

export function getAllQueryParams() {
  let params = {}
  params.theme = checkSupportedTheme(getQueryParam(window.location, 'theme'))

  params.inputCurrency = isAddress(getQueryParam(window.location, 'inputCurrency'))
    ? isAddress(getQueryParam(window.location, 'inputCurrency'))
    : ''
  params.outputCurrency = isAddress(getQueryParam(window.location, 'outputCurrency'))
    ? isAddress(getQueryParam(window.location, 'outputCurrency'))
    : getQueryParam(window.location, 'outputCurrency') === MAIN_TOKEN
    ? MAIN_TOKEN
    : ''
  params.slippage = !isNaN(getQueryParam(window.location, 'slippage')) ? getQueryParam(window.location, 'slippage') : ''
  params.exactField = getQueryParam(window.location, 'exactField')
  params.exactAmount = !isNaN(getQueryParam(window.location, 'exactAmount'))
    ? getQueryParam(window.location, 'exactAmount')
    : ''
  params.theme = checkSupportedTheme(getQueryParam(window.location, 'theme'))
  params.recipient = isAddress(getQueryParam(window.location, 'recipient'))
    ? getQueryParam(window.location, 'recipient')
    : ''

  // Add Liquidity params
  params.ethAmount = !isNaN(getQueryParam(window.location, 'ethAmount'))
    ? getQueryParam(window.location, 'ethAmount')
    : ''
  params.tokenAmount = !isNaN(getQueryParam(window.location, 'tokenAmount'))
    ? getQueryParam(window.location, 'tokenAmount')
    : ''
  params.token = isAddress(getQueryParam(window.location, 'token'))
    ? isAddress(getQueryParam(window.location, 'token'))
    : ''

  // Remove liquidity params
  params.poolTokenAmount = !isNaN(getQueryParam(window.location, 'poolTokenAmount'))
    ? getQueryParam(window.location, 'poolTokenAmount')
    : ''
  params.poolTokenAddress = isAddress(getQueryParam(window.location, 'poolTokenAddress'))
    ? isAddress(getQueryParam(window.location, 'poolTokenAddress'))
      ? isAddress(getQueryParam(window.location, 'poolTokenAddress'))
      : ''
    : ''

  // Create Exchange params
  params.tokenAddress = isAddress(getQueryParam(window.location, 'tokenAddress'))
    ? isAddress(getQueryParam(window.location, 'tokenAddress'))
    : ''

  return params
}

export function checkSupportedTheme(themeName) {
  if (themeName && themeName.toUpperCase() in SUPPORTED_THEMES) {
    return themeName.toUpperCase()
  }
  return null
}

export function getNetworkName(networkId) {
  switch (networkId) {
    case 1: {
      return 'Nebulas Mainnet'
    }
    case 1000: {
      return 'Nebulas Testnet'
    }
    default: {
      return 'the incorrect network'
    }
  }
}

export function shortenAddress(address, digits = 4) {
  let addressLower = address.toLowerCase()
  if (!addressLower.startsWith('n1') && !isAddress(address)) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${address.substring(0, digits + 2)}...${address.substring(address.length - digits)}`
}

export function shortenTransactionHash(hash, digits = 4) {
  return `${hash.substring(0, digits + 2)}...${hash.substring(66 - digits)}`
}

export function isAddress(address) {
  try {
    const addressLower = address.toLowerCase()
    if (addressLower.startsWith('n') && address.length === 35) {
      return true
    }

    return false
  } catch {
    return false
  }
}

export function formatNasBalance(balance) {
  return amountFormatter(balance, 18, 18, false)
}

export function formatTokenBalance(balance, decimal) {
  return !!(balance && Number.isInteger(decimal)) ? amountFormatter(balance, decimal, Math.min(4, decimal)) : 0
}

export function formatToUsd(price) {
  const format = { decimalSeparator: '.', groupSeparator: ',', groupSize: 3 }
  const usdPrice = formatFixed(price, {
    decimalPlaces: 2,
    dropTrailingZeros: false,
    format
  })
  return usdPrice
}

// amount must be a BigNumber, {base,display}Decimals must be Numbers
export function amountFormatter(amount, baseDecimals = 18, displayDecimals = 3, useLessThan = true) {
  if (baseDecimals > 18 || displayDecimals > 18 || displayDecimals > baseDecimals) {
    throw Error(`Invalid combination of baseDecimals '${baseDecimals}' and displayDecimals '${displayDecimals}.`)
  }

  // if balance is falsy, return undefined
  if (!amount) {
    return undefined
  }
  // if amount is 0, return
  else if (amount.isZero()) {
    return '0'
  }
  // amount > 0
  else {
    // amount of 'wei' in 1 'ether'
    const baseAmount = ethers.utils.bigNumberify(10).pow(ethers.utils.bigNumberify(baseDecimals))

    const minimumDisplayAmount = baseAmount.div(
      ethers.utils.bigNumberify(10).pow(ethers.utils.bigNumberify(displayDecimals))
    )

    // if balance is less than the minimum display amount
    if (amount.lt(minimumDisplayAmount)) {
      return useLessThan
        ? `<${ethers.utils.formatUnits(minimumDisplayAmount, baseDecimals)}`
        : `${ethers.utils.formatUnits(amount, baseDecimals)}`
    }
    // if the balance is greater than the minimum display amount
    else {
      const stringAmount = ethers.utils.formatUnits(amount, baseDecimals)

      // if there isn't a decimal portion
      if (!stringAmount.match(/\./)) {
        return stringAmount
      }
      // if there is a decimal portion
      else {
        const [wholeComponent, decimalComponent] = stringAmount.split('.')
        const roundedDecimalComponent = ethers.utils
          .bigNumberify(decimalComponent.padEnd(baseDecimals, '0'))
          .toString()
          .padStart(baseDecimals, '0')
          .substring(0, displayDecimals)

        // decimals are too small to show
        if (roundedDecimalComponent === '0'.repeat(displayDecimals)) {
          return wholeComponent
        }
        // decimals are not too small to show
        else {
          return `${wholeComponent}.${roundedDecimalComponent.toString().replace(/0*$/, '')}`
        }
      }
    }
  }
}

// caculate exchange rate
export function caculateExchangeRate(factor, value1, dicimal1, value2, dicimal2) {
  try {
    const rate = bigNumberify(value1)
      .mul(factor)
      .mul(bigNumberify(10).pow(bigNumberify(dicimal2)))
      .div(bigNumberify(10).pow(bigNumberify(dicimal1)))
      .div(bigNumberify(value2))

    // console.log('caculateExchangeRate', { rate, factor, value1, dicimal1, value2, dicimal2 })

    return rate
  } catch (err) {
    console.log('caculateExchangeRate err', err)
  }
}

// get exchange rate
export function getExchangeRate(input1Value, input1Decimals, input2Value, input2Decimals, invert = false) {
  try {
    if (input1Value && input1Decimals && input2Value && input2Decimals) {
      let factor

      if (invert) {
        factor = bigNumberify(10).pow(bigNumberify(input2Decimals))
        return caculateExchangeRate(factor, input1Value, input1Decimals, input2Value, input2Decimals)
      } else {
        factor = bigNumberify(10).pow(bigNumberify(input1Decimals))
        return caculateExchangeRate(factor, input2Value, input2Decimals, input1Value, input1Decimals)
      }
    }
  } catch {
    console.log("getExchangeRate err")
  }
}
