import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { transparentize, darken } from 'polished'
import Tooltip from '@reach/tooltip'
import { amountFormatter } from 'utils'

import { Button } from 'theme'
import Modal from '../Modal'
import { BorderlessInput } from 'theme'
import { ReactComponent as Close } from 'assets/images/x.svg'
import { useTransactionAdder, useAllTransactions } from 'contexts/Transactions'

import { useStake } from 'hooks/useStake'
import { usePoolUnstake } from 'hooks/usePoolUnstake'

import { bigNumberify } from 'ethers/utils'

// neb
import { useWeb3React } from 'hooks'

// modal
import { useModal } from 'hooks/useModal'
import CallContractModal from 'components/CallContractModal'

const ModalContainer = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  width: 100%;
`

const ModalHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 0px 1rem;
  height: 60px;
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.textColor};
  }
`

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const InputPanel = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  background-color: ${({ theme }) => theme.inputBackground};
  z-index: 1;
  padding: 1rem;
`

const Container = styled.div`
  border-radius: 0;
  border: 1px solid ${({ error, theme }) => (error ? theme.salmonRed : theme.mercuryGray)};

  background-color: ${({ theme }) => theme.inputBackground};
  :focus-within {
    border: 1px solid ${({ theme }) => theme.royalBlue};
  }
`

const LabelRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.doveGray};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.doveGray)};
  }
`

const LabelContainer = styled.div`
  flex: 1 1 auto;
  width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const InputRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;

  padding: 0.25rem 0.85rem 0.75rem;
`

const Input = styled(BorderlessInput)`
  font-size: 1.5rem;
  color: ${({ error, theme }) => error && theme.salmonRed};
  background-color: ${({ theme }) => theme.inputBackground};
  -moz-appearance: textfield;
`

const ErrorSpan = styled.span`
  color: ${({ error, theme }) => error && theme.salmonRed};
  :hover {
    cursor: pointer;
    color: ${({ error, theme }) => error && darken(0.1, theme.salmonRed)};
  }
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledTokenName = styled.span`
  margin: 0 0.25rem 0 0.25rem;
`

const Flex = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;

  button {
    max-width: 20rem;
  }
`

const StakeModal = ({ type = 'stake', poolId, staked = 0, balance, lpPair, isOpen, onDismiss }) => {
  const { useNano } = useWeb3React()
  const [stakeValue, setStakeValue] = useState('')

  const { onStake, getContractParams: getStakeContractParams } = useStake()
  const { onUnstake, getContractParams: getUnstakeContractParams } = usePoolUnstake()

  const { t } = useTranslation()

  function clearInputAndDismiss() {
    setStakeValue('')
    onDismiss()
  }

  const max = type === 'stake' ? balance : bigNumberify(staked)
  const balanceText = type === 'stake' ? t('balance') : t('staked')

  // is valid action
  const isValid = () => {
    return stakeValue && stakeValue > 0 && stakeValue <= amountFormatter(max, 18, 18)
  }

  const addTransaction = useTransactionAdder()

  // show stake qrcode modal
  const [onShowStakeModal] = useModal(<CallContractModal title={t('stake')} />)

  // show unstake qrcode modal
  const [onShowUnstakeModal] = useModal(<CallContractModal title={t('unstake')} />)

  async function onClickStake() {
    if (useNano) {
      const contractParams = getStakeContractParams(poolId, stakeValue)
      onShowStakeModal(contractParams)
    } else {
      try {
        const txhash = await onStake(poolId, stakeValue)
        addTransaction(txhash)
      } catch (err) {
        console.log('onStake error', err)
      }
    }
  }

  async function onClickUnstake() {
    if (useNano) {
      const contractParams = getUnstakeContractParams(poolId, stakeValue)
      onShowUnstakeModal(contractParams)
    } else {
      try {
        const txhash = await onUnstake(poolId, stakeValue)
        addTransaction(txhash)
      } catch (err) {
        console.log('onUnstake error', err)
      }
    }
  }

  return (
    <Modal isOpen={isOpen} onDismiss={clearInputAndDismiss} width={40} height={250}>
      <ModalContainer>
        <ModalHeader>
          <p>{type === 'stake' ? t('stake') : t('unstake')}</p>
          <CloseIcon onClick={clearInputAndDismiss}>
            <CloseColor alt={'close icon'} />
          </CloseIcon>
        </ModalHeader>

        <InputPanel>
          <Container>
            <LabelRow>
              <LabelContainer>{/* <span>Input {type} amount</span> */}</LabelContainer>

              <ErrorSpan
                data-tip={'Enter max'}
                onClick={() => {
                  setStakeValue(amountFormatter(max, 18, 18))
                }}
              >
                <span>
                  {balanceText}
                  {max ? amountFormatter(max, 18, 18) : '-'}
                </span>
              </ErrorSpan>
            </LabelRow>

            <InputRow>
              <Input
                type="number"
                min="0"
                step="0.000000000000000001"
                placeholder="0.0"
                onChange={e => setStakeValue(e.target.value)}
                onKeyPress={e => {
                  const charCode = e.which ? e.which : e.keyCode
                  // Prevent 'minus' character
                  if (charCode === 45) {
                    e.preventDefault()
                    e.stopPropagation()
                  }
                }}
                value={stakeValue}
              />
              <StyledTokenName>{lpPair} LP</StyledTokenName>
            </InputRow>
          </Container>
        </InputPanel>
        <Flex>
          {type === 'stake' && (
            <Button disabled={!isValid()} onClick={onClickStake}>
              {t('stakeNow')}
            </Button>
          )}

          {type === 'unstake' && (
            <Button disabled={!isValid()} onClick={onClickUnstake}>
              {t('unstake')}
            </Button>
          )}
        </Flex>
      </ModalContainer>
    </Modal>
  )
}

export default StakeModal
