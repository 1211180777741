import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useWeb3React, UnsupportedChainIdError } from 'contexts/web3'
import { darken, transparentize } from 'polished'
import { Activity } from 'react-feather'

import { shortenAddress } from '../../utils'
import WalletModal from '../WalletModal'
import { useAllTransactions } from '../../contexts/Transactions'
import { useWalletModalToggle } from '../../contexts/Application'
import { Spinner } from '../../theme'
import Circle from '../../assets/images/circle.svg'
import { injected } from '../../connectors'
// import WalletConnectIcon from '../../assets/images/walletConnectIcon.svg'
// import CoinbaseWalletIcon from '../../assets/images/coinbaseWalletIcon.svg'
// import FortmaticIcon from '../../assets/images/fortmaticIcon.png'
// import PortisIcon from '../../assets/images/portisIcon.png'
// import TorusIcon from '../../assets/images/torus.png'
// import { NetworkContextName } from '../../constants'
import Identicon from '../Identicon'


// modal
import { useModal } from "hooks/useModal"
import WalletProviderModal from "components/WalletProviderModal";

// icon
import { TiLink } from "react-icons/ti"

const Web3StatusGeneric = styled.button`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  font-size: 0.9rem;
  align-items: center;
  padding: 0.5rem;
  border-radius: 2rem;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  :focus {
    outline: none;
  }
`
const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.salmonRed};
  border: 1px solid ${({ theme }) => theme.salmonRed};
  color: ${({ theme }) => theme.white};
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.1, theme.salmonRed)};
  }
`

const Web3StatusConnect = styled(Web3StatusGeneric)`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.royalBlue};
  color: ${({ theme }) => theme.royalBlue};
  font-weight: 500;

  :hover,
  :focus {
    border: 1px solid ${({ theme }) => darken(0.1, theme.royalBlue)};
    color: ${({ theme }) => darken(0.1, theme.royalBlue)};
  }

  ${({ faded }) =>
    faded &&
    css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.royalBlue};
      color: ${({ theme }) => theme.royalBlue};

      :hover,
      :focus {
        border: 1px solid ${({ theme }) => darken(0.1, theme.royalBlue)};
        color: ${({ theme }) => darken(0.1, theme.royalBlue)};
      }
    `}
`

const Web3StatusConnected = styled(Web3StatusGeneric)`
  background-color: ${({ pending, theme }) => (pending ? theme.zumthorBlue : theme.inputBackground)};
  border: 1px solid ${({ pending, theme }) => (pending ? theme.royalBlue : theme.mercuryGray)};
  color: ${({ pending, theme }) => (pending ? theme.royalBlue : theme.doveGray)};
  font-weight: 400;
  :hover {
    background-color: ${({ pending, theme }) =>
      pending ? transparentize(0.9, theme.royalBlue) : darken(0.05, theme.inputBackground)};

    :focus {
      border: 1px solid
        ${({ pending, theme }) => (pending ? darken(0.1, theme.royalBlue) : darken(0.1, theme.mercuryGray))};
    }
  }
`

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 0.83rem;
`

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

const SpinnerWrapper = styled(Spinner)`
  margin: 0 0.25rem 0 0.25rem;
`

const IconWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  & > * {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
`

export default function Web3Status() {
  const { t } = useTranslation()
  const { active, account, connector, provider, error } = useWeb3React()

  console.log('Web3Status', connector, active, account, provider, error)

  // const contextNetwork = useWeb3React()

  const allTransactions = useAllTransactions()
  const pending = Object.keys(allTransactions).filter(hash => !allTransactions[hash].receipt)
  // success txs
  const confirmed = Object.keys(allTransactions).filter(hash => allTransactions[hash].receipt && allTransactions[hash].status === 1)

  // failed txs
  const failed = Object.keys(allTransactions).filter(hash => allTransactions[hash].receipt && allTransactions[hash].status === 0)

  // failed 的交易

  const hasPendingTransactions = !!pending.length

  const toggleWalletModal = useWalletModalToggle()

  // open wallet provider select modal
  const [onPresentWalletProviderModal] = useModal(
    <WalletProviderModal />
  );

  // handle the logo we want to show with the account
  function getStatusIcon() {
    if (account) {
      return <Identicon />
    }
  }

  function getWeb3Status() {
    if (account) {
      return (
        <Web3StatusConnected onClick={toggleWalletModal} pending={hasPendingTransactions}>
          {hasPendingTransactions && <SpinnerWrapper src={Circle} alt="loader" />}
          <Text>{shortenAddress(account)}</Text>
          {getStatusIcon()}
        </Web3StatusConnected>
      )
    } else if (error) {
      // return (
      //   <button onClick={onPresentWalletProviderModal}>connect to wallet</button>
      // )
    } else {
      // install wallet & not select wallet file, can't read account
      return (
        <Web3StatusConnect onClick={onPresentWalletProviderModal}>
          <TiLink />
          <Text>{t('connect-nebulas-wallet')}</Text>
        </Web3StatusConnect>
      )
    }
  }

  // if (!contextNetwork.active && !active) {
  //   return null
  // }

  return (
    <>
      {getWeb3Status()}
      {/* <button onClick={onPresentWalletProviderModal}>connect to wallet</button> */}
      <WalletModal pendingTransactions={pending} confirmedTransactions={confirmed}  failedTransactions={failed} />
    </>
  )
}
