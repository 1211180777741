import React from 'react'
import styled from 'styled-components'
import { Flex, Box, Text } from 'rebass/styled-components'
import logo from 'assets/images/nextdao@2x.png'
import { NavLink } from 'react-router-dom'
import Container from 'components/Container'
import Web3Status from 'components/Web3Status'

const Header = () => {
  return (
    <StyledContainer>
      <Container>
        <StyledTopBar>
          <StyledLogoWrapper>
            <StyledLink target="__blank" rel="noreferrer noopener" href="https://nextdao.io/">
              <StyledLogo src={logo} />
            </StyledLink>
            <StyledNavLink exact to="/">
              <StyledText>Swap <StyledVersion>v1.0</StyledVersion></StyledText>
            </StyledNavLink>
          </StyledLogoWrapper>
          <Box mx="auto" />
          {/* <StyledNavs>
            <StyledNavItem exact activeClassName="active" to="/event">
              Event
            </StyledNavItem>
          </StyledNavs> */}
          <WrapperWallet>
            <Web3Status />
          </WrapperWallet>
        </StyledTopBar>
      </Container>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(134, 141, 167, 0.2);
`

const StyledTopBar = styled(Flex)`
  height: 70px;
`

const StyledLogoWrapper = styled(Flex)`
  align-items: center;
`

const StyledLogo = styled.img`
  height: 30px;
  width: auto;
`

const StyledText = styled(Text)`
  margin-left: 20px;
  color: ${({ theme }) => theme.primary.main};
`

const StyledVersion = styled.span`
  font-size: 8px;
`

const StyledNavs = styled(Flex)`
  align-items: center;
`

const StyledNavItem = styled(NavLink)`
  margin: 0 22px;
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
`

const WrapperWallet = styled(Flex)`
  padding: 1rem 0;
`

const StyledLink = styled.a``

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`

export default Header
