import { useState, useCallback, useEffect } from 'react'
import { useWeb3React } from '.'
import { POOL_ADDRESS } from '../constants'
import { Nebulas } from 'utils/neb'

// get stake pool info
const useGetPool = poolId => {
  const { chainId } = useWeb3React()
  const [pairInfo, setPairInfo] = useState()

  const stake_address = POOL_ADDRESS[chainId]

  const fetchPoolInfo = useCallback(async () => {
    const neb = new Nebulas()
    try {
      const func = 'getPool'
      const args = [poolId]

      const result = await neb.read({
        to: stake_address,
        func,
        args
      })

      if (result) {
        setPairInfo(result)
      }

      console.log('fetchPoolInfo', func, args, result)
    } catch (err) {
      console.log('fetchPoolInfo error', err)
    }
  }, [])

  useEffect(() => {
    fetchPoolInfo(poolId)
  }, [])

  return {
    pairInfo
  }
}

export { useGetPool }
