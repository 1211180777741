import { useCallback } from 'react'
import { useWeb3React } from '.'
import { POOL_ADDRESS } from '../constants'
import { Nebulas } from 'utils/neb'
import { toNasBasic } from 'utils/nebFormat'

// stake LP token
const useStake = () => {
  const { chainId } = useWeb3React()

  const stake_address = POOL_ADDRESS[chainId]

  const getContractParams = (poolId, amount) => {
    const func = 'deposit'
    const args = [poolId, toNasBasic(amount)]
    return {
      to: stake_address,
      func,
      args,
      value: 0
    }
  }

  const onStake = useCallback(
    async (poolId, amount) => {
      const neb = new Nebulas()

      try {
        const { to, func, args, value } = getContractParams(poolId, amount)

        const txhash = await neb.send({
          to,
          func,
          args,
          value
        })

        return txhash
      } catch (err) {
        console.log('on stake error', err)
      }
    },
    []
  )

  return {
    onStake,
    getContractParams
  }
}

export { useStake }
