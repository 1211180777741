import React, { createContext, useCallback, useState } from 'react'
import styled from 'styled-components'

export const Context = createContext({
  onPresent: () => {},
  onDismiss: () => {},
  params: null
})

const Modals = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [content, setContent] = useState()
  const [modalParams, setModalParams] = useState()

  const handlePresent = useCallback(
    (modalContent, params) => {
    setModalParams(params)
      setContent(modalContent)
      setIsOpen(true)
    },
    [setContent, setIsOpen, setModalParams]
  )

  const handleDismiss = useCallback(() => {
    setContent(undefined)
    setIsOpen(false)
  }, [setContent, setIsOpen])

  return (
    <Context.Provider
      value={{
        content,
        isOpen,
        onPresent: handlePresent,
        onDismiss: handleDismiss,
        params: modalParams
      }}
    >
      {children}
      {isOpen && (
        <StyledModalWrapper>
          <StyledModalBackdrop onClick={handleDismiss} />
          {React.isValidElement(content) &&
            React.cloneElement(content, {
              isOpen,
              onDismiss: handleDismiss,
              params: modalParams
            })}
        </StyledModalWrapper>
      )}
    </Context.Provider>
  )
}

const StyledModalWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const StyledModalBackdrop = styled.div`
  background-color: ${props => props.theme.bg4}aa;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export default Modals
