import { ethers } from 'ethers'

import { Nebulas } from 'utils/neb'

import { isAddress } from './index'

const { bigNumberify } = ethers.utils

export function getNasBalance(address) {
  if (address && !isAddress(address)) {
    throw Error(`Invalid 'address' parameter '${address}'`)
  }

  const neb = new Nebulas()
  return neb.getBalance(address)
}

// get the token total supply
export async function getTokenSupply(tokenAddress) {
  if (!isAddress(tokenAddress)) {
    throw Error(`Invalid 'tokenAddress' parameter '${tokenAddress}'.`)
  }
  const neb = new Nebulas()
  const totalSupply = await neb.read({
    to: tokenAddress,
    func: 'totalSupply',
    args: []
  })

  console.log('getTokenSupply', {
    totalSupply
  })

  return totalSupply
}

// get the token balance of an address
export async function getTokenBalance(tokenAddress, address) {
  if (!isAddress(tokenAddress) || !isAddress(address)) {
    throw Error(`Invalid 'tokenAddress' or 'address' parameter '${tokenAddress}' or '${address}'.`)
  }

  try {
    const neb = new Nebulas()
    const balanceOf = await neb.read({
      to: tokenAddress,
      func: 'balanceOf',
      args: [address]
    })

    //   console.log('getTokenBalance', {
    //     tokenAddress,
    //     address,
    //     balanceOf
    //   })

    return balanceOf
  } catch (err) {
    console.log('getTokenBalance err', err)
  }
}

// get the token allowance
export async function getTokenAllowance(address, tokenAddress, spenderAddress) {
  console.log('getTokenAllowance args', {
    address,
    tokenAddress,
    spenderAddress
  })

  if (!isAddress(address) || !isAddress(tokenAddress) || !isAddress(spenderAddress)) {
    throw Error(
      "Invalid 'address' or 'tokenAddress' or 'spenderAddress' parameter" +
        `'${address}' or '${tokenAddress}' or '${spenderAddress}'.`
    )
  }

  const neb = new Nebulas()

  try {
    const allowance = await neb.read({
      to: tokenAddress,
      func: 'allowance',
      args: [address, spenderAddress]
    })

    return bigNumberify(allowance)
  } catch (err) {
    return bigNumberify(0)
  }
}

export async function getAllowanceAndBalnce(address, tokenAddress, spenderAddress) {
  if (!isAddress(tokenAddress) || !isAddress(spenderAddress)) {
    throw Error("Invalid 'tokenAddress' or 'spenderAddress' parameter" + `'${tokenAddress}' or '${spenderAddress}'.`)
  }
  const allowance = await getTokenAllowance(address, tokenAddress, spenderAddress)

  const balance = await getTokenBalance(tokenAddress, address)

  return {
    allowance: allowance.toString(),
    balance
  }
}

// approve token to spender
export async function approveToken(address, tokenAddress, spenderAddress, currentValue, approveValue) {
  const { allowance, balance } = await getAllowanceAndBalnce(address, tokenAddress, spenderAddress)

  currentValue = currentValue || allowance
  approveValue = approveValue || balance

  // console.log('approveToken args', {
  //   tokenAddress,
  //   spenderAddress,
  //   currentValue: allowance,
  //   approveValue
  // })

  const neb = new Nebulas()
  const res = await neb.send({
    to: tokenAddress,
    func: 'approve',
    args: [spenderAddress, currentValue, approveValue]
  })

  console.log('approveToken res', res)

  return res
}
