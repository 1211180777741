import React, { useState, useEffect } from 'react'
import { useWeb3React } from 'contexts/web3'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useEagerConnect } from 'hooks'
import { Spinner, Link } from '../../theme'
import Circle from '../../assets/images/circle.svg'

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20rem;
  flex-direction: column;
`

const Message = styled.h2`
  color: ${({ theme }) => theme.uniswapPink};
`

const SpinnerWrapper = styled(Spinner)`
  font-size: 4rem;

  svg {
    path {
      color: ${({ theme }) => theme.uniswapPink};
    }
  }
`

const Blurb = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin: 1rem;
    font-size: 12px;
  `};
`

export default function Web3ReactManager({ children }) {
  const { t } = useTranslation()
  const { account, active, error: networkError, activate: activateNetwork } = useWeb3React()

  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  // after eagerly trying injected, if the network connect ever isn't active or in an error state, activate itd
  // TODO think about not doing this at all
  useEffect(() => {
    if (triedEager && !networkError && !active) {
      activateNetwork()
    }
  }, [triedEager, networkError, activateNetwork, active])

  // 'pause' the network connector if we're ever connected to an account and it's active
  // useEffect(() => {
  //   if (active && networkActive) {
  //     network.pause()
  //   }
  // }, [active, networkActive])

  // 'resume' the network connector if we're ever not connected to an account and it's active
  // useEffect(() => {
  //   if (!active && networkActive) {
  //     network.resume()
  //   }
  // }, [active, networkActive])

  // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists

  // handle delayed loader state
  const [showLoader, setShowLoader] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoader(true)
    }, 600)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  // installed nas ext wallet, but no select wallet file, can't read account
  // if (!triedEager) {
  //   return (
  //     <MessageWrapper>
  //       <Message>{t('unlockNasWallet')}</Message>
  //     </MessageWrapper>
  //   )
  // }

  // // if the account context isn't active, and there's an error on the network context, it's an irrecoverable error
  // if (!active && networkError) {
  //   return (
  //     <MessageWrapper>
  //       {account}
  //       <Message>
  //         <Link href="https://chrome.google.com/webstore/detail/nasextwallet/gehjkhmhclgnkkhpfamakecfgakkfkco">
  //           {t('installNasWallet')}
  //         </Link>
  //       </Message>
  //       <Blurb>
  //         <Link href="https://github.com/nebulasio/WebExtensionWallet">Github</Link>
  //       </Blurb>
  //     </MessageWrapper>
  //   )
  // }

  // // if neither context is active, spin
  // if (!active) {
  //   return showLoader ? (
  //     <MessageWrapper>
  //       <SpinnerWrapper src={Circle} />
  //     </MessageWrapper>
  //   ) : null
  // }

  return children
}
