import React, { createContext, useContext, useReducer, useMemo, useCallback, useEffect } from 'react'

import _ from 'lodash'

import { INITIAL_TOKENS_CONTEXT, NAME, SYMBOL, DECIMALS, EXCHANGE_ADDRESS, SETUP, MAIN_TOKEN } from '../constants'

import { useWeb3React } from '../hooks'
import { safeAccess } from '../utils'

export { INITIAL_TOKENS_CONTEXT }

const UPDATE = 'UPDATE'

const NAS = {
  NAS: {
    [NAME]: 'Nebulas Token',
    [SYMBOL]: 'NAS',
    [DECIMALS]: 18,
    [EXCHANGE_ADDRESS]: null,
    [SETUP]: true
  }
}

const TokensContext = createContext()

function useTokensContext() {
  return useContext(TokensContext)
}

function reducer(state, { type, payload }) {
  switch (type) {
    case UPDATE: {
      const { networkId, tokenAddress, name, symbol, decimals, exchangeAddress, setup } = payload
      return {
        ...state,
        [networkId]: {
          ...(safeAccess(state, [networkId]) || {}),
          [tokenAddress]: {
            [NAME]: name,
            [SYMBOL]: symbol,
            [DECIMALS]: decimals,
            [EXCHANGE_ADDRESS]: exchangeAddress,
            [SETUP]: setup
          }
        }
      }
    }
    default: {
      throw Error(`Unexpected action type in TokensContext reducer: '${type}'.`)
    }
  }
}

export default function Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, INITIAL_TOKENS_CONTEXT)

  const update = useCallback((networkId, tokenAddress, name, symbol, decimals, exchangeAddress) => {
    dispatch({ type: UPDATE, payload: { networkId, tokenAddress, name, symbol, decimals, exchangeAddress } })
  }, [])

  return (
    <TokensContext.Provider value={useMemo(() => [state, { update }], [state, update])}>
      {children}
    </TokensContext.Provider>
  )
}

// get token detail by symbol
export function useTokenDetailBySymbol(_symbol) {
  const { chainId } = useWeb3React()

  const [state] = useTokensContext()
  const allTokensInNetwork = { ...NAS, ...(safeAccess(state, [chainId]) || {}) }

  console.log("allTokensInNetwork", allTokensInNetwork, _symbol);

  const tokenFind = _.find(allTokensInNetwork, { symbol: _symbol })

  // console.log('useTokenDetailBySymbol', _symbol, tokenFind)

  const { [NAME]: name, [SYMBOL]: symbol, [DECIMALS]: decimals, [EXCHANGE_ADDRESS]: exchangeAddress } = tokenFind

  return useMemo(() => ({ name, symbol, decimals, exchangeAddress }), [name, symbol, decimals, exchangeAddress])
}

// get token detail by address
export function useTokenDetails(tokenAddress) {
  const { chainId } = useWeb3React()

  const [state] = useTokensContext()
  const allTokensInNetwork = { ...NAS, ...(safeAccess(state, [chainId]) || {}) }

  const { [NAME]: name, [SYMBOL]: symbol, [DECIMALS]: decimals, [EXCHANGE_ADDRESS]: exchangeAddress } =
    safeAccess(allTokensInNetwork, [tokenAddress]) || {}

  // console.log('useTokenDetails', tokenAddress, allTokensInNetwork)

  return useMemo(() => ({ name, symbol, decimals, exchangeAddress }), [name, symbol, decimals, exchangeAddress])
}

// get all token details
export function useAllTokenDetails() {
  const { chainId } = useWeb3React()

  const [state] = useTokensContext()

  return useMemo(() => ({ ...NAS, ...(safeAccess(state, [chainId]) || {}) }), [state, chainId])
}
