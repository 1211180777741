import React, { createContext, useContext, useReducer, useState, useRef, useMemo, useCallback, useEffect } from 'react'
import { BigNumber } from '@uniswap/sdk'
import { ethers } from 'ethers'

import { useWeb3React, useDebounce } from '../hooks'
import { isAddress } from '../utils'
import { getNasBalance, getTokenBalance } from '../utils/nebUtil'

import { useBlockNumber } from './Application'
import { useAllTokenDetails } from './Tokens'
// import { getUSDPrice } from '../utils/price'
import { SWAP_ADDRESSES } from '../constants'

const MAIN_TOKEN = 'NAS'

const LOCAL_STORAGE_KEY = 'BALANCES'
const SHORT_BLOCK_TIMEOUT = (60 * 2) / 15 // in seconds, represented as a block number delta
const LONG_BLOCK_TIMEOUT = (60 * 15) / 15 // in seconds, represented as a block number delta

const EXCHANGES_BLOCK_TIMEOUT = (60 * 5) / 15 // in seconds, represented as a block number delta

function initialize() {
  try {
    return JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY))
  } catch {
    return {}
  }
}

const Action = {
  START_LISTENING: 'START_LISTENING',
  STOP_LISTENING: 'STOP_LISTENING',
  UPDATE: 'UPDATE',
  BATCH_UPDATE_ACCOUNT: 'BATCH_UPDATE_ACCOUNT',
  BATCH_UPDATE_EXCHANGES: 'BATCH_UPDATE_EXCHANGES'
}

function reducer(state, { type, payload }) {
  switch (type) {
    case Action.START_LISTENING: {
      const { chainId, address, tokenAddress } = payload
      const uninitialized = !!!state?.[chainId]?.[address]?.[tokenAddress]
      return {
        ...state,
        [chainId]: {
          ...state?.[chainId],
          [address]: {
            ...state?.[chainId]?.[address],
            [tokenAddress]: uninitialized
              ? {
                  listenerCount: 1
                }
              : {
                  ...state[chainId][address][tokenAddress],
                  listenerCount: state[chainId][address][tokenAddress].listenerCount + 1
                }
          }
        }
      }
    }
    case Action.STOP_LISTENING: {
      const { chainId, address, tokenAddress } = payload
      return {
        ...state,
        [chainId]: {
          ...state?.[chainId],
          [address]: {
            ...state?.[chainId]?.[address],
            [tokenAddress]: {
              ...state?.[chainId]?.[address]?.[tokenAddress],
              listenerCount: state[chainId][address][tokenAddress].listenerCount - 1
            }
          }
        }
      }
    }
    case Action.UPDATE: {
      const { chainId, address, tokenAddress, value, blockNumber } = payload
      return {
        ...state,
        [chainId]: {
          ...state?.[chainId],
          [address]: {
            ...state?.[chainId]?.[address],
            [tokenAddress]: {
              ...state?.[chainId]?.[address]?.[tokenAddress],
              value,
              blockNumber
            }
          }
        }
      }
    }
    case Action.BATCH_UPDATE_ACCOUNT: {
      const { chainId, address, tokenAddresses, values, blockNumber } = payload
      return {
        ...state,
        [chainId]: {
          ...state?.[chainId],
          [address]: {
            ...state?.[chainId]?.[address],
            ...tokenAddresses.reduce((accumulator, tokenAddress, i) => {
              const value = values[i]
              accumulator[tokenAddress] = {
                ...state?.[chainId]?.[address]?.[tokenAddress],
                value,
                blockNumber
              }
              return accumulator
            }, {})
          }
        }
      }
    }
    case Action.BATCH_UPDATE_EXCHANGES: {
      const { chainId, exchangeAddresses, tokenAddresses, values, blockNumber } = payload

      return {
        ...state,
        [chainId]: {
          ...state?.[chainId],
          ...exchangeAddresses.reduce((accumulator, exchangeAddress, i) => {
            const tokenAddress = tokenAddresses[i]
            const value = values[i]
            accumulator[exchangeAddress] = {
              ...state?.[chainId]?.[exchangeAddress],
              ...accumulator?.[exchangeAddress],
              [tokenAddress]: {
                ...state?.[chainId]?.[exchangeAddress]?.[tokenAddress],
                value,
                blockNumber
              }
            }
            return accumulator
          }, {})
        }
      }
    }
    default: {
      throw Error(`Unexpected action type in BalancesContext reducer: '${type}'.`)
    }
  }
}

const BalancesContext = createContext([{}, {}])

function useBalancesContext() {
  return useContext(BalancesContext)
}

export default function Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, undefined, initialize)

  const startListening = useCallback((chainId, address, tokenAddress) => {
    dispatch({ type: Action.START_LISTENING, payload: { chainId, address, tokenAddress } })
  }, [])

  const stopListening = useCallback((chainId, address, tokenAddress) => {
    dispatch({ type: Action.STOP_LISTENING, payload: { chainId, address, tokenAddress } })
  }, [])

  const update = useCallback((chainId, address, tokenAddress, value, blockNumber) => {
    dispatch({ type: Action.UPDATE, payload: { chainId, address, tokenAddress, value, blockNumber } })
  }, [])

  const batchUpdateAccount = useCallback((chainId, address, tokenAddresses, values, blockNumber) => {
    dispatch({ type: Action.BATCH_UPDATE_ACCOUNT, payload: { chainId, address, tokenAddresses, values, blockNumber } })
  }, [])

  const batchUpdateExchanges = useCallback((chainId, exchangeAddresses, tokenAddresses, values, blockNumber) => {
    dispatch({
      type: Action.BATCH_UPDATE_EXCHANGES,
      payload: { chainId, exchangeAddresses, tokenAddresses, values, blockNumber }
    })
  }, [])

  return (
    <BalancesContext.Provider
      value={useMemo(
        () => [state, { startListening, stopListening, update, batchUpdateAccount, batchUpdateExchanges }],
        [state, startListening, stopListening, update, batchUpdateAccount, batchUpdateExchanges]
      )}
    >
      {children}
    </BalancesContext.Provider>
  )
}

export function Updater() {
  const { chainId, account, library } = useWeb3React()
  const blockNumber = useBlockNumber()
  const [state, { update, batchUpdateAccount, batchUpdateExchanges }] = useBalancesContext()

  // debounce state a little bit to prevent useEffect craziness
  const debouncedState = useDebounce(state, 1000)
  // cache this debounced state in localstorage
  useEffect(() => {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(debouncedState))
  }, [debouncedState])

  // (slightly janky) balances-wide cache to prevent double/triple/etc. fetching
  const fetchedAsOfCache = useRef({})

  // generic balances fetcher abstracting away difference between fetching ETH + token balances
  const fetchBalance = useCallback(
    (address, tokenAddress) => {
      // console.log('fetchBalance', address, tokenAddress)
      return (tokenAddress === MAIN_TOKEN ? getNasBalance(account) : getTokenBalance(tokenAddress, account, library))
        .then(value => {
          // console.log('value', { value })
          return value.toString()
        })
        .catch(err => {
          console.error(err, { tokenAddress, address })
          return null
        })
    },
    [account, library]
  )

  // ensure that all balances with >=1 listeners are updated every block
  useEffect(() => {
    console.log('get all balances chainId', chainId, 'blockNumber', blockNumber)

    if (typeof chainId === 'number' && typeof blockNumber === 'number') {
      for (const address of Object.keys(debouncedState?.[chainId] ?? {})) {
        for (const tokenAddress of Object.keys(debouncedState?.[chainId][address])) {
          const active = debouncedState[chainId][address][tokenAddress].listenerCount > 0
          if (active) {
            const cachedFetchedAsOf = fetchedAsOfCache.current?.[chainId]?.[address]?.[tokenAddress]
            const fetchedAsOf = debouncedState[chainId][address][tokenAddress]?.blockNumber ?? cachedFetchedAsOf
            if (fetchedAsOf !== blockNumber) {
              // fetch the balance...
              fetchBalance(address, tokenAddress)
                .then(value => {
                  update(chainId, address, tokenAddress, value, blockNumber)
                })
                .catch(err => {
                  console.error('error fetching balance', address, tokenAddress)
                })
              // ...and cache the fetch
              fetchedAsOfCache.current = {
                ...fetchedAsOfCache.current,
                [chainId]: {
                  ...fetchedAsOfCache.current?.[chainId],
                  [address]: {
                    ...fetchedAsOfCache.current?.[chainId]?.[address],
                    [tokenAddress]: blockNumber
                  }
                }
              }
            }
          }
        }
      }
    }
  }, [chainId, blockNumber, debouncedState, fetchBalance, update])

  // get a state ref for batch updates
  const stateRef = useRef(state)
  useEffect(() => {
    stateRef.current = state
  }, [state])
  const allTokenDetails = useAllTokenDetails()

  // ensure that we have the user balances for all tokens
  const allTokens = useMemo(() => Object.keys(allTokenDetails), [allTokenDetails])

  useEffect(() => {
    if (typeof chainId === 'number' && typeof account === 'string' && typeof blockNumber === 'number') {
      Promise.all(
        allTokens
          .filter(tokenAddress => {
            const hasValue = !!stateRef.current?.[chainId]?.[account]?.[tokenAddress]?.value
            const cachedFetchedAsOf = fetchedAsOfCache.current?.[chainId]?.[account]?.[tokenAddress]
            const fetchedAsOf = stateRef.current?.[chainId]?.[account]?.[tokenAddress]?.blockNumber ?? cachedFetchedAsOf

            // if there's no value, and it's not being fetched, we need to fetch!
            if (!hasValue && typeof cachedFetchedAsOf !== 'number') {
              return true
              // else, if there's a value, check if it's stale
            } else if (hasValue) {
              const blocksElapsedSinceLastCheck = blockNumber - fetchedAsOf
              const stale =
                blocksElapsedSinceLastCheck >=
                (stateRef.current[chainId][account][tokenAddress].value === '0'
                  ? LONG_BLOCK_TIMEOUT
                  : SHORT_BLOCK_TIMEOUT)
              return stale
            } else {
              return false
            }
          })
          .map(async tokenAddress => {
            fetchedAsOfCache.current = {
              ...fetchedAsOfCache.current,
              [chainId]: {
                ...fetchedAsOfCache.current?.[chainId],
                [account]: {
                  ...fetchedAsOfCache.current?.[chainId]?.[account],
                  [tokenAddress]: blockNumber
                }
              }
            }
            return fetchBalance(account, tokenAddress).then(value => ({ tokenAddress, value }))
          })
      ).then(results => {
        batchUpdateAccount(
          chainId,
          account,
          results.map(result => result.tokenAddress),
          results.map(result => result.value),
          blockNumber
        )
      })
    }
  }, [chainId, account, blockNumber, allTokens, fetchBalance, batchUpdateAccount])

  // ensure that we have the eth and token balances for all exchanges
  const allExchanges = useMemo(
    () =>
      Object.keys(allTokenDetails)
        .filter(tokenAddress => tokenAddress !== MAIN_TOKEN)
        .map(tokenAddress => ({
          tokenAddress,
          exchangeAddress: allTokenDetails[tokenAddress].exchangeAddress
        })),
    [allTokenDetails]
  )
  useEffect(() => {
    if (typeof chainId === 'number' && typeof blockNumber === 'number') {
      Promise.all(
        allExchanges
          .filter(({ exchangeAddress, tokenAddress }) => {
            const hasValueToken = !!stateRef.current?.[chainId]?.[exchangeAddress]?.[tokenAddress]?.value
            const hasValueETH = !!stateRef.current?.[chainId]?.[exchangeAddress]?.[MAIN_TOKEN]?.value

            const cachedFetchedAsOfToken = fetchedAsOfCache.current?.[chainId]?.[exchangeAddress]?.[tokenAddress]
            const cachedFetchedAsOfETH = fetchedAsOfCache.current?.[chainId]?.[exchangeAddress]?.[MAIN_TOKEN]

            const fetchedAsOfToken =
              stateRef.current?.[chainId]?.[exchangeAddress]?.[tokenAddress]?.blockNumber ?? cachedFetchedAsOfToken
            const fetchedAsOfETH =
              stateRef.current?.[chainId]?.[exchangeAddress]?.[MAIN_TOKEN]?.blockNumber ?? cachedFetchedAsOfETH

            // if there's no values, and they're not being fetched, we need to fetch!
            if (
              (!hasValueToken || !hasValueETH) &&
              (typeof cachedFetchedAsOfToken !== 'number' || typeof cachedFetchedAsOfETH !== 'number')
            ) {
              return true
              // else, if there are values, check if they's stale
            } else if (hasValueToken && hasValueETH) {
              const blocksElapsedSinceLastCheckToken = blockNumber - fetchedAsOfToken
              const blocksElapsedSinceLastCheckETH = blockNumber - fetchedAsOfETH

              const stale =
                fetchedAsOfToken !== fetchedAsOfETH ||
                blocksElapsedSinceLastCheckToken >= EXCHANGES_BLOCK_TIMEOUT ||
                blocksElapsedSinceLastCheckETH >= EXCHANGES_BLOCK_TIMEOUT
              return stale
            } else {
              return false
            }
          })
          .map(async ({ exchangeAddress, tokenAddress }) => {
            fetchedAsOfCache.current = {
              ...fetchedAsOfCache.current,
              [chainId]: {
                ...fetchedAsOfCache.current?.[chainId],
                [exchangeAddress]: {
                  ...fetchedAsOfCache.current?.[chainId]?.[exchangeAddress],
                  [tokenAddress]: blockNumber,
                  ETH: blockNumber
                }
              }
            }
            return Promise.all([
              fetchBalance(exchangeAddress, tokenAddress),
              fetchBalance(exchangeAddress, MAIN_TOKEN)
            ]).then(([valueToken, valueETH]) => ({ exchangeAddress, tokenAddress, valueToken, valueETH }))
          })
      ).then(results => {
        batchUpdateExchanges(
          chainId,
          results.flatMap(result => [result.exchangeAddress, result.exchangeAddress]),
          results.flatMap(result => [result.tokenAddress, MAIN_TOKEN]),
          results.flatMap(result => [result.valueToken, result.valueETH]),
          blockNumber
        )
      })
    }
  }, [chainId, account, blockNumber, allExchanges, fetchBalance, batchUpdateExchanges])

  return null
}

export function useAllBalances() {
  const { chainId } = useWeb3React()
  const [state] = useBalancesContext()
  return useMemo(() => (typeof chainId === 'number' ? state?.[chainId] ?? {} : {}), [chainId, state])
}

export function useAddressBalance(address, tokenAddress) {
  const { chainId } = useWeb3React()
  const [state, { startListening, stopListening }] = useBalancesContext()

  useEffect(() => {
    if (typeof chainId === 'number' && isAddress(address) && isAddress(tokenAddress)) {
      startListening(chainId, address, tokenAddress)
      return () => {
        stopListening(chainId, address, tokenAddress)
      }
    }
  }, [chainId, address, tokenAddress])

  const value = typeof chainId === 'number' ? state?.[chainId]?.[address]?.[tokenAddress]?.value : undefined

  // console.log('useAddressBalance', { address, tokenAddress, value })

  return useMemo(() => (typeof value === 'string' ? ethers.utils.bigNumberify(value) : value), [value])
}

async function getTokenPriceInUSD(tokenId) {
  const response = await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${tokenId}&vs_currencies=usd`)
  const data = await response.json()
  const price = data[tokenId].usd
  //console.log(`tron price ${price}`)
  return new BigNumber(price)
}

export function useNASPriceInUSD() {
  const { chainId } = useWeb3React()
  const [price, setPrice] = useState()

  useEffect(() => {
    const updatePrice = async () => {
      const price = await getTokenPriceInUSD('nebulas')
      setPrice(price)
    }
    updatePrice()
    const timer = setInterval(updatePrice, 100000)
    return () => clearInterval(timer)
  }, [chainId])

  return price
}
