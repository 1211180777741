import React, { useState } from 'react'
import styled from 'styled-components'

import { MAIN_TOKEN } from '../../constants'
import nasLogo from 'assets/token/nas.png'
import naxLogo from 'assets/token/nax.png'
import wnasLogo from 'assets/token/wnas.png'
import nusdtLogo from 'assets/token/nusdt.png'

const BAD_IMAGES = {}

const Image = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

const Emoji = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function TokenLogo({ address, size = '1rem', symbol = '', ...rest }) {
  const [error, setError] = useState(false)

  let path = ''
  if (address === MAIN_TOKEN) {
    path = nasLogo
  } else if (symbol === 'nUSDT') {
    path = nusdtLogo
  } else if (symbol === 'wNAS') {
    path = wnasLogo
  } else if (symbol === 'NAX') {
    path = naxLogo
  } else {
    return (
      <Emoji {...rest} size={size}>
        <span role="img" aria-label="Thinking">
          🤔
        </span>
      </Emoji>
    )
  }

  return (
    <Image
      {...rest}
      alt={address}
      src={path}
      size={size}
      onError={() => {
        BAD_IMAGES[address] = true
        setError(true)
      }}
    />
  )
}
