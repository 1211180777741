import React, { createContext, useContext, useMemo } from 'react'
import invariant from 'tiny-invariant'

import { useWeb3ReactManager } from './manager'
import { getNebNetwork } from 'utils/nebWalletConnector'

export const PRIMARY_KEY = 'primary'
const CONTEXTS = {}

export function createWeb3ReactRoot(key) {
  invariant(!CONTEXTS[key], `A root already exists for provided key ${key}`)

  CONTEXTS[key] = createContext({
    activate: async () => {
      invariant(false, 'No <Web3ReactProvider ... /> found.')
    },
    setError: () => {
      invariant(false, 'No <Web3ReactProvider ... /> found.')
    },
    deactivate: () => {
      invariant(false, 'No <Web3ReactProvider ... /> found.')
    },
    active: false
  })
  CONTEXTS[key].displayName = `Web3ReactContext - ${key}`

  const Provider = CONTEXTS[key].Provider

  return function Web3ReactProvider({ getLibrary, children }) {
    const {
      connector,
      provider,
      chainId,
      account,

      connectNano,
      activate,
      setError,
      deactivate,

      error
    } = useWeb3ReactManager()

    // const active = connector !== undefined && chainId !== undefined && account !== undefined && !!!error

    const active = account !== undefined

    // const library = useMemo(
    //   () =>
    //     active && chainId !== undefined && Number.isInteger(chainId) && !!connector
    //       ? getLibrary(provider, connector)
    //       : undefined,
    // [active, getLibrary, provider, connector, chainId])
    const library = getLibrary();

    const network = getNebNetwork();

    const web3ReactContext = {
      connector,
      library,
      chainId: network.networkId,
      account,
      provider,
      useNano: provider === 'nano' ? true : false,
      connectNano,
      activate,
      setError,
      deactivate,

      active,
      error
    }

    return <Provider value={web3ReactContext}>{children}</Provider>
  }
}

export const Web3ReactProvider = createWeb3ReactRoot(PRIMARY_KEY)

export function getWeb3ReactContext(key = PRIMARY_KEY) {
  invariant(Object.keys(CONTEXTS).includes(key), `Invalid key ${key}`)
  return CONTEXTS[key]
}

export function useWeb3React(key) {
  return useContext(getWeb3ReactContext(key))
}
