import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Box, Text } from 'rebass/styled-components'
import { useTranslation } from 'react-i18next'
import AddressInput from 'components/AddressInput'

// utils
import { isNebValidAddress } from 'utils/nebFormat'

import { Button } from 'theme'
import { useWeb3React } from 'hooks'

const AssociateAddressInput = ({ onAssociate }) => {
  const { t } = useTranslation()
  const [address, setAddress] = useState('')
  const [invalid, setInvalid] = useState(true)

  const { connectNano } = useWeb3React()

  const onChange = value => {
    setAddress(value)
  }

  const handleAssociate = () => {
    if (isNebValidAddress(address)) {
      connectNano(address)
      onAssociate()
    }
  }

  return (
    <StyledWrapper>
      <StyledInputWrapper>
        <StyledText>{t('associate-nebulas-address')}</StyledText>
        <StyledAddrInputWrapper>
          <AddressInput value={address} setInvalid={setInvalid} onChange={onChange} />
        </StyledAddrInputWrapper>
      </StyledInputWrapper>

      <Button disabled={invalid} onClick={handleAssociate}>
        {t('associate')}
      </Button>
    </StyledWrapper>
  )
}

const StyledWrapper = styled(Flex)`
  flex-direction: column;
`
const StyledInputWrapper = styled(Flex)`
  flex-direction: column;
  margin-bottom: 12px;
`

const StyledAddrInputWrapper = styled.div`
  border: ${props => `1px solid ${props.theme.border1}`};
  padding: 0 12px 12px;
`

const StyledText = styled(Text)`
  margin-bottom: 6px;
`

export default AssociateAddressInput
