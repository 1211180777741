import { useState, useCallback, useEffect } from 'react'

import { useWeb3React } from '../hooks'
import { MAIN_TOKEN, SWAP_ADDRESSES } from '../constants'
import { Nebulas } from 'utils/neb'
import { useTokenDetailBySymbol } from 'contexts/Tokens'
import { toNonExponential } from 'utils/math'

// get token1,2 pool reserve balance in swap contract
const useSwapReserves = (token1Address, token2Address) => {
  // const { exchangeAddress } = useTokenDetails(tokenAddress)
  const { chainId } = useWeb3React()
  const wNAS = useTokenDetailBySymbol('wNAS')

  const swap_address = SWAP_ADDRESSES[chainId]

  const [reserve1, setReserve1] = useState()
  const [reserve2, setReserve2] = useState()
  const [lpTokenAddress, setLpTokenAddress] = useState()

  const fetchPairInfo = useCallback(
    async (token1Address, token2Address) => {

      if(!token1Address || !token2Address) {
        return;
      }

      let swapToken = false
      //   console.log('fetchPairInfo args', token1Address, token2Address)
      // if token1,2 has NAS, change to wNAS address
      if (token1Address === MAIN_TOKEN) {
        token1Address = wNAS.exchangeAddress
      }

      if (token2Address === MAIN_TOKEN) {
        token2Address = wNAS.exchangeAddress
      }

      let tokenA = token1Address
      let tokenB = token2Address

      if (token1Address > token2Address) {
        tokenA = token2Address
        tokenB = token1Address
        swapToken = true
      }

      if (tokenA && tokenB) {
        try {
          const neb = new Nebulas()
          const res = await neb.read({ to: swap_address, func: 'getPair', args: [tokenA, tokenB] })

          let reserve1, reserve2

          if (!swapToken) {
            reserve1 = res.reserve0
            reserve2 = res.reserve1
          } else {
            reserve1 = res.reserve1
            reserve2 = res.reserve0
          }

          reserve1 = toNonExponential(reserve1)
          reserve2 = toNonExponential(reserve2)

          const lpTokenAddr = res.lp

          console.log('start useSwapReserves', { tokenA, tokenB, reserve1, reserve2, lpTokenAddr })

          setReserve1(reserve1)
          setReserve2(reserve2)

          setLpTokenAddress(lpTokenAddr)
        } catch (err) {
          console.log('fetch useSwapReserves error', err)
        }
      }
    },
    [swap_address, wNAS]
  )

  useEffect(() => {

    // if (token1Address && token2Address) {
    //   fetchPairInfo(token1Address, token2Address)
    // } 

    const updateReserve = () => {
      fetchPairInfo(token1Address, token2Address);
    }

    fetchPairInfo(token1Address, token2Address);

    const timer = setInterval(updateReserve, 10*1000)

    return () => {
      clearInterval(timer);
      setReserve1()
      setReserve2()
      setLpTokenAddress()
    }
  }, [token1Address, token2Address, fetchPairInfo])

  return { reserve1, reserve2, lpTokenAddress, fetchPairInfo }
}

export { useSwapReserves }
