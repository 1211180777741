import React, { Suspense, lazy } from 'react'
import styled from 'styled-components'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import Web3ReactManager from '../components/Web3ReactManager'
import Nav from '../components/Nav'
import Header from '../components/Header'
import Footer from '../components/Footer'

// import NavigationTabs from '../components/NavigationTabs'
import { isAddress, getAllQueryParams } from '../utils'
import bg from 'assets/images/bg.png'

// pages
import Swap from "./Swap"
import Send from "./Send"
import Pool from "./Pool"
import Stake from "./Stake"

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  height: 100vh;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`
const FooterWrapper = styled.div`
  width: 100%;
  min-height: 30px;
  align-self: flex-end;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  overflow: auto;
  padding-top: 3rem;
  background-image: url(${bg});
  background-size: contain;
  background-repeat: no-repeat;
`

const Body = styled.div`
  max-width: 30rem;
  margin-top: 0;
  width: 90%;
  padding: 25px 15px;
  box-shadow: rgba(134, 141, 167, 0.15) 0px 0px 10px 0px;
  background-color: #fff;
  /* margin: 0 1.25rem 1.25rem 1.25rem; */
`

/*
const Announcement = styled.div`
  width: 100%;
  display: block;
  background-color: #202124;
  border-bottom: 2px solid #000;
  text-align: center;
  color: #46bf89;
  font-size: 1.2em;
  font-weight: bold;
  & a {
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
    color: #46bf89;
    font-weight: bold;
    text-decoration: none;
  }
`
*/

export default function App() {
  const params = getAllQueryParams()
  return (
    <>
      <Suspense fallback={null}>
          <AppWrapper>
            <BrowserRouter>
              {/*
          <Announcement>
          </Announcement>
          */}
              <HeaderWrapper>
                <Header />
              </HeaderWrapper>
              <BodyWrapper>
                {/* <Body> */}
                <Web3ReactManager>
                  {/* <NavigationTabs /> */}
                  <Nav />
                  {/* this Suspense is for route code-splitting */}
                  <Suspense fallback={null}>
                    <Switch>
                      <Route exact strict path="/swap" component={() => <Swap params={params} />} />
                      <Route
                        exact
                        strict
                        path="/swap/:tokenAddress?"
                        render={({ match, location }) => {
                          let tokenAddress = match.params.tokenAddress

                          if (isAddress(tokenAddress)) {
                            return <Swap location={location} initialCurrency={tokenAddress} params={params} />
                          } else {
                            return <Redirect to={{ pathname: '/swap' }} />
                          }
                        }}
                      />
                      <Route exact strict path="/send" component={() => <Send params={params} />} />
                      <Route
                        exact
                        strict
                        path="/send/:tokenAddress?"
                        render={({ match }) => {
                          let tokenAddress = match.params.tokenAddress

                          if (isAddress(tokenAddress)) {
                            return <Send initialCurrency={tokenAddress} params={params} />
                          } else {
                            return <Redirect to={{ pathname: '/send' }} />
                          }
                        }}
                      />
                      <Route
                        path={[
                          '/add-liquidity',
                          '/remove-liquidity'
                          // '/create-exchange',
                          // '/create-exchange/:tokenAddress?'
                        ]}
                        component={() => <Pool params={params} />}
                      />

                      <Route exact strict path="/stake" component={() => <Stake params={params} />} />

                      <Redirect to="/swap" />
                    </Switch>
                  </Suspense>
                </Web3ReactManager>
                {/* </Body> */}
              </BodyWrapper>
              <FooterWrapper>
                <Footer />
              </FooterWrapper>
            </BrowserRouter>
          </AppWrapper>
      </Suspense>
    </>
  )
}