import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { darken, transparentize } from 'polished'
import Toggle from 'react-switch'

import { Link } from '../../theme'
// import { useDarkModeManager } from '../../contexts/LocalStorage'

const FooterFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const FooterElement = styled.div`
  margin: 1.25rem;
  display: flex;
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.uniswapPink};

  

  & > a {
    margin-right: 1rem;
    &:hover {
      cursor: pointer;
    }
  }
`

const Text = styled.span`
color: ${({ theme }) => theme.text3};

`

// const StyledToggle = styled(Toggle)`
//   margin-right: 24px;

//   .react-switch-bg[style] {
//     background-color: ${({ theme }) => darken(0.05, theme.inputBackground)} !important;
//     border: 1px solid ${({ theme }) => theme.concreteGray} !important;
//   }

//   .react-switch-handle[style] {
//     background-color: ${({ theme }) => theme.inputBackground};
//     box-shadow: 0 4px 8px 0 ${({ theme }) => transparentize(0.93, theme.shadowColor)};
//     border: 1px solid ${({ theme }) => theme.mercuryGray};
//     border-color: ${({ theme }) => theme.mercuryGray} !important;
//     top: 2px !important;
//   }
// `

// const EmojiToggle = styled.span`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
// `

export default function Footer() {
  // const [isDark, toggleDarkMode] = useDarkModeManager()
  const { t } = useTranslation()

  return (
    <FooterFrame>
      <FooterElement>
        <Title>
          <Link href="https://link.nextdao.io/">NextDAO.Link</Link>

          <Link href={t("documentUrl")}>{t('document')}</Link>
          <Link href="https://github.com/nebulasio/swap-contract">{t('contracts')}</Link>
          <Link href="https://swap.nextdao.io/">Swap v1.1</Link>
        </Title>
        <Title>
          <Text>v1.0</Text>
        </Title>
      </FooterElement>
    </FooterFrame>
  )
}
