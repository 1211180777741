import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Flex, Box, Text } from 'rebass/styled-components'
import QRCode from 'qrcode.react'

import { toNasBasic } from 'utils/nebFormat'
import { useWeb3React } from 'hooks'
import { SWAP_ADDRESSES } from '../../constants'

// images
import appstoreIcon from 'assets/images/download-on-the-appstore.png'
import googlePlayIcon from 'assets/images/get-it-on-google-play.png'

// modal
import Modal from 'components/Modal'
import { ReactComponent as Close } from 'assets/images/x.svg'


const CallContractModal = ({ onDismiss, isOpen, params, title = '' }) => {
  const { chainId } = useWeb3React()

  const { t } = useTranslation()
  const { to, func, args, value=0 } = params

  function genQrcodeStr() {
    const qrcodeStr = JSON.stringify({
      pageParams: {
        pay: {
          currency: 'NAS',
          value: toNasBasic(value),
          to,
          gasLimit: '800000',
          gasPrice: '20000000000',
          payload: {
            function: func,
            args: JSON.stringify(args),
            type: 'call'
          }
        }
      },
      des: 'confirmTransfer',
      category: 'jump'
    })

    console.log('qrcode', qrcodeStr)
    return qrcodeStr
  }

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} width={40} maxHeight={false}>
       <ModalContainer>
        <ModalHeader>
          <p>{title}</p>
          <CloseIcon onClick={onDismiss}>
            <CloseColor alt={'close icon'} />
          </CloseIcon>
        </ModalHeader>


      <Container>
        <StyledWrapper>
          <StyledQRCode>
            <QRCode size={256} value={genQrcodeStr()} />
          </StyledQRCode>

          <StyledNanoWrapper>
            <h3>{t('use-nano-scan')}</h3>
            <StyledAppDownload>
              <a href=" https://itunes.apple.com/us/app/nas-nano-pro/id1441776793&mt=8" target="__blank">
                <img alt="appstore" src={appstoreIcon} />
              </a>
              <a
                href=" https://play.google.com/store/apps/details?id=io.nebulas.wallet.android&hl=en_US"
                target="__blank"
              >
                <img alt="google-play" src={googlePlayIcon} />
              </a>
            </StyledAppDownload>
          </StyledNanoWrapper>
        </StyledWrapper>
      </Container>
      </ModalContainer>
    </Modal>
  )
}

const ModalContainer = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  width: 100%;
`

const ModalHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 0px 1rem;
  height: 60px;
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.textColor};
  }
`

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`


const Container = styled.div`
  border-radius: 0;
  border-top: 1px solid ${({ error, theme }) => (error ? theme.salmonRed : theme.mercuryGray)};

  background-color: ${({ theme }) => theme.inputBackground};
  :focus-within {
    border-top: 1px solid ${({ theme }) => theme.royalBlue};
  }
`


const StyledWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  padding: 25px 0 10px;
`

const StyledNanoWrapper = styled(Flex)`
  flex-direction: column;
  padding-top: 8px;
  justify-content: center;
  align-items: center;

  h3 {
    font-size: 1rem;
  }
`

const StyledAppDownload = styled(Flex)`
  img {
    width: auto;
    height: 42px;
    padding: 6px;
  }
`

const StyledQRCode = styled(Flex)`
  justify-content: center;
`

export default CallContractModal
